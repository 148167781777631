/*loader processing*/
.loader-container {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    p {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 48px;
        line-height: 1.5;
        text-align: center;
        color: $color-primary;
        // position: absolute;
        // top: 55%;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        z-index: 10;
        padding: 0 16px;
        margin-top: 2vh;
    }
    .banner-title {
        font-size: 16px;
        color: #9e9e9e;
        height: auto;
    }
    svg {
        fill: none;
        position: absolute;
        z-index: 5;
    }
    .line-loader-mob {
        display: none;
        top: -20px;
        right: 0;
        width: 100%;
    }
    .line-loader1 {
        right: 0;
        top: 0;
        width: 75%;
    }
    .line-loader2 {
        left: 0;
        bottom: 0;
        width: 46%;
        max-width: 700px;
    }
}

.loader-block {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    width: 100%;
    margin-top: -14vh;
    .banner {
        // max-width: 800px;
        width: 100%;
        //height: auto !important;
        position: relative;
        // display: flex;
        // justify-content: center;
        // margin-top: 4vh;
        margin-top: 0;
        z-index: 50;
    }
}

.loader {
    // animation: loader 3s linear infinite;
    // position: absolute;
    // top: 40%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    z-index: 10;
}

.box {
    width: 100px;
    height: 100px;
    background-color: #a4e6a7;
    animation: animate .8s linear infinite;
    border-radius: 16px;
}

.shadow {
    width: 100px;
    height: 10px;
    background-color: $color-primary;
    opacity: 0.1;
    position: absolute;
    top: 110px;
    left: 0;
    border-radius: 50%;
    animation: shadow-box .8s linear infinite;
}

@keyframes loader {
    0% {
        left: -200px;
    }

    100% {
        left: 150%;
    }
}

@keyframes animate {
    17% {
        border-bottom-right-radius: 3px;
    }

    25% {
        transform: translateY(9px) rotate(22.5deg);
    }

    50% {
        transform: translateY(18px) scale(1, .9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }

    75% {
        transform: translateY(9px) rotate(67.5deg);
    }

    100% {
        transform: translateY(0) rotate(90deg);
    }
}

@keyframes shadow-box {
    50% {
        transform: scale(1.2, 1);
    }
}

@media all and (max-width: 900px) {
    .loader-container {
        .line-loader-mob {
            display: block;
        }
        .line-loader1,.line-loader2 {
        display: none;
        }
        p {
            font-size: 24px;
            line-height: 1.67;
        }
    }
}

/*loader roller*/
$loader-duration: 1.2s;
$loader-iteration-count: infinite;
$loader-direction: normal;

.loader-roller-wrapper{
  width: 80px; 
  height: 50px;
  position: relative;
  margin: 0 auto;
}
.loader-roller {
	width:80px; 
    height: 50px; 
    top: 0;
    left: 0;
    position: absolute;  
    &:after {
        content: "";
        top: auto;
        position: absolute;
        display: block;
        animation: shadow $loader-duration $loader-iteration-count linear;
        bottom: 0em;
        left: 0;
        height: 4px;
        width: 12px;
        border-radius: 50%;
        background-color: #000;
    }

    .roller {
        width: 40px; 
        height: 40px; 
        position: absolute;
        top: 0;
        animation-iteration-count:  $loader-iteration-count;
        animation-timing-function: linear;
        animation-duration: $loader-duration;
    }

    .roller:first-child {
        left: 0;
        animation-name: rollercoaster;      
        transform: rotate(135deg);
    }

    .roller:last-child{
        right: 0;
        animation-name: rollercoaster2;
        transform: rotate(-45deg);
    }

    .roller:before {
        content:""; 
        display:block;
        width: 12px;
        height: 12px;
        background-color: #a4e6a7;
        border-radius: 50%;
    }
}

.loader-roller2 {
    &:after {
        animation-delay: .15s;
    }
    .roller {
        animation-delay: .15s;
    }
}

.loader-roller3 {
    &:after {
        animation-delay: .3s;
    }
  
    .roller {
        animation-delay: .3s;
    }
}

@keyframes rollercoaster {
    0%    { transform: rotate(135deg);}
    8%    { transform: rotate(240deg);}
    20%   { transform: rotate(300deg);}
    40%   { transform: rotate(380deg);}
    45%   { transform: rotate(440deg);}
    50%   { transform: rotate(495deg); opacity: 1;}
    50.1% { transform: rotate(495deg); opacity: 0;}
    100%  { transform: rotate(495deg); opacity: 0;}
}

@keyframes rollercoaster2 {
    0%    { opacity: 0;}
    49.9% { opacity: 0;}
    50%   { opacity:1; transform: rotate(-45deg);}
    58%   { transform: rotate(-160deg);}
    70%   { transform: rotate(-240deg);}
    80%   { transform: rotate(-300deg);}
    90%   { transform: rotate(-340deg);}
    100%  { transform: rotate(-405deg);}
}

@keyframes shadow {
    0%   { opacity:.3; transform: translateX(35px) scale(0.25,0.25);}
    8%   { transform: translateX(9px) scale(1,1);}
    20%  { transform: translateX(0px) scale(.6,.6);}
    40%  { transform: translateX(-5px) scale(.25,.25); opacity: 0.1;}
    50%  { transform: translateX(32px) scale(.6,.6); opacity: 0.3;}
    60%  { transform: translateX(70px) scale(1,1); opacity: 0.05;}
    65%  { transform: translateX(78px) scale(.6,.6);}
    80%  { transform: translateX(65px) scale(.25,.25); opacity: 0.1;}
    90%  { transform: translateX(43px) scale(.4,.4);}
    100% { transform: translateX(32px); opacity: 0.3;}
}

@media all and (max-width: 500px) {
    .loader-block {
        margin-top: -10vh;
    }
    .loader-container p {
        height: 80px;
    }
}

@media all and (max-height: 630px) {
    .loader-block {
        margin-top: -4vh;
    }
}
