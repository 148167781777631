body {
    background-color: #fff;
}

.logo {
    font-family: $font-logo;
    font-size: 32px;
    line-height: 1;
    color: $color-primary;
    text-transform: uppercase;
    margin-right: 24px;
}

/*main page*/
.main-page {
    .line-main {
        position: absolute;
        top: -56px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 1200px;
        fill: none;
        z-index: 5;
    }

    .line-main-mob {
        display: none;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        fill: none;
        z-index: 5;
    }
}

header {
    position: relative;
    z-index: 15;
    background: #fff;

    .header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 16px;
        box-sizing: border-box;
        max-width: 1152px;
        margin: 0 auto;

        @media all and (max-width: 991px) {
            padding: 12px 0;
        }
    }
}

.header-main-content {
    display: flex;
    align-items: center;

    .btn-contacts-form {
        margin-right: 48px;

        @media all and (max-width: 991px) {
            display: none;
        }
    }
}

.header-other-content {
    display: flex;
    align-items: center;
}

.main-page-content {
    display: grid;
    grid-template-columns: auto 640px;
    margin-bottom: 64px;
    margin-top: 64px;

    @media all and (max-width: 991px) {
        margin-top: 32px;
    }

    .wr-slider-images {
        position: relative;
        grid-column: 2/3;
        grid-row: 1/3;
        align-self: flex-start;
        position: relative;
        z-index: 10;
        left: -12px;

        .slide {
            display: flex;
            justify-content: flex-end;
            padding: 0;

            img {
                width: 100%;
            }
        }

        picture {
            width: 96%;
        }

        .slick-dots {
            bottom: 19px;
        }
    }
}

.main-page-title {
    font-family: $font-primary;
    font-weight: 600;
    font-size: 64px;
    line-height: 1.38;
    text-align: left;
    position: relative;
    z-index: 10;
    margin-bottom: 16px;

    span {
        border-radius: 8px;
        display: inline-block;
        position: relative;
        padding-right: 4px;

        &:before {
            content: "";
            display: block;
            background-color: #70ff76;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-1deg);
            width: 122%;
            height: 76px;
            z-index: -4;
            border-radius: 38px;
        }
    }
}

.main-page-subtitle {
    line-height: 1.5;
    text-align: left;
    margin-top: 80px;
}

.wr-slider-images {
    width: 100%;

    .slick-list,
    .slick-slider {
        position: relative;
        border-radius: 32px;
    }

    .slick-dots {
        bottom: 3px;

        li {
            width: 6px;
            height: 6px;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            vertical-align: middle;
            margin: 0 4px;
        }

        button {
            background-color: $color-white;
            border-radius: 3px;
            width: 100%;
            height: 100%;
        }

        .slick-active {
            width: 42px;

            button {
                background-color: $color-white;
            }
        }
    }

    .slick-active {
        .slide {
            border-color: #ffdd0d;
        }
    }

    .slide {
        display: flex;
        justify-content: center;
        border-radius: 32px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        padding: 0 20px;
        margin: 0 auto;

        img {
            border-radius: 32px;
            width: 312px;
        }
    }

    .square {
        width: 180px;
        height: 180px;
        border-radius: 32px;
        // border: dashed 2px $color-green;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='32' ry='32' stroke='%23A4E6A7FF' stroke-width='4' stroke-dasharray='10%2c 18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 32px;
        position: absolute;
        bottom: -40px;
        right: -20px;
        z-index: -5;
        margin: 0;
    }
}

.btn-upload-foto-desktop {
    margin: 0 auto 160px;
}

.wr-slider-logos {
    width: 100%;
    position: static;
    box-sizing: border-box;
    margin-bottom: 170px;

    .slider {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        row-gap: 53px;
    }

    .slick-track {
        display: flex;
        align-items: center;
    }

    .slick-dots {
        bottom: -20px;

        li {
            font-size: 0;
            width: 6px;
            height: 6px;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            vertical-align: middle;
            margin: 0 4px;
        }

        button {
            font-size: 0;
            background-color: $color-grey;
            border-radius: 3px;
            width: 100%;
            height: 100%;
        }

        .slick-active {
            width: 42px;

            button {
                background-color: $color-grey;
            }
        }
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        width: 100%;
    }
}

.main-page-labs-container {
    grid-column: 1/3;
    grid-row: 4/5;
    align-self: flex-end;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin-top: 160px;
    margin-bottom: 0;

    img {
        max-width: 440px;
    }
}

.main-page-labs-info {
    width: 50%;
    padding-top: 88px;

    .main-page-title {
        margin-bottom: 40px;

        span::before {
            content: none;
        }
    }

    .btn-go-labs {
        font-size: 24px;
        background-color: $color-red;
        width: 320px;
        height: 80px;
        border-radius: 20px;
        box-sizing: border-box;
        padding-top: 3px;
        margin: 0;
    }
}

.main-page-unite-container {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    picture {
        width: 56%;
        flex-shrink: 0;
    }

    img {
        width: 100%;
    }
}

.main-page-unite-info {
    margin-left: -32px;

    .main-page-title {
        margin-bottom: 40px;
        max-width: 570px;

        span {
            color: #f6704d;

            &:before {
                content: none;
            }
        }
    }

    .btn-go-unite {
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.96px;
        width: 320px;
        height: 80px;
        border-radius: 20px;
        background-color: #4d5ef6;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-weight: 600;
            margin-left: 8px;
        }
    }
}

.btns-market-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-bottom: 80px;
}

.btn-upload-foto-desktop {
    svg {
        animation: rotate 10s linear infinite;
    }

    &-title {
        display: none;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 60px;
}

.posts-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;

    a {
        width: 100%;
        display: block;
    }
}

.instagram {
    margin-bottom: 40px;

    .main-page-title {
        font-size: 32px;

        @media all and (max-width: 900px) {
            font-size: 24px;
        }
    }

    .post {
        width: 32%;
        overflow: hidden;
        margin-bottom: 40px;
    }

    .post-image {
        position: relative;
        margin-bottom: 8px;

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        img {
            border-radius: 24px;
            width: 100%;
        }
    }

    .user-post {
        margin-bottom: 20px;
    }

    .user-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .user-name {
        display: flex;
        align-items: center;
        max-width: 80%;

        img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 8px;
        }

        p {
            font-family: $font-primary;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.5;
            color: $color-primary;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .user-likes {
        display: flex;
        align-items: center;

        p {
            font-family: $font-primary;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.5;
            color: $color-primary;
        }

        span {
            font-family: $font-primary;
            font-weight: 400;
        }

        svg {
            width: 24px;
            height: 24px;
            margin-left: 8px;
        }
    }
}

.instagram-page {
    padding-top: 24px;
    padding-bottom: 60px;

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .wr-slider-images {
        margin-bottom: 8px;

        .slide {
            padding: 0;

            img {
                width: 100%;
            }
        }
    }

    .user-post {
        width: 25%;
        margin: 0 10px 30px;
    }

    .user-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .user-name {
        display: flex;
        align-items: center;
        max-width: 80%;

        img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 8px;
        }

        p {
            font-family: $font-primary;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.5;
            color: $color-primary;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .user-likes {
        flex-shrink: 0;

        p {
            font-family: $font-primary;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.5;
            color: $color-primary;
        }

        span {
            font-family: $font-primary;
            font-weight: 400;
        }
    }

    .instagram {
        .main-page-title {
            font-size: 22px;
        }
    }
}

.title-contacts-form {
    font-size: 24px;
    text-align: left;
    line-height: 1.5;
    margin-bottom: 80px;
}

/*collage-page*/
.collage-page {
    box-sizing: border-box;
    padding-bottom: 20px;

    &-content {
        position: relative;
        display: flex;
        align-items: flex-start;

        .line-result-mob {
            display: none;
            width: calc(100% + 40px);
            position: absolute;
            bottom: -80px;
            left: -20px;
            fill: none;
            z-index: -3;
        }

        .tabs-container {
            flex-direction: column;
            flex-shrink: 0;
            width: auto;
            position: static;
            max-width: 450px;
            max-height: 600px;
            overflow: auto;
            z-index: 40;
            padding: 0;
            margin: 0;
            padding-right: 4px;
            margin-right: 20px;

            &::-webkit-scrollbar-track {
                border-radius: 8px;
                background-color: transparent;
            }

            &::-webkit-scrollbar {
                width: 4px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: #cdcdcd;
            }
        }

        .btns-container {
            max-width: 450px;
            margin: 0 auto;
        }
    }

    .line-result {
        width: calc(100% + 40px);
        position: absolute;
        top: -20px;
        right: 0;
        width: 85%;
        max-width: 1800px;
        fill: none;
        pointer-events: none;
    }

    .tab-custom {
        flex-shrink: 0;
        max-width: 540px;
        width: 100%;
        margin: 0;
    }

    header {
        margin-bottom: 9vh;
    }

    .creative-holder-container {
        max-width: 540px;
        width: 100%;
    }

    .creative-holder-flex {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .creative-holder {
        display: flex;
        position: relative;
        width: 100%;
        max-width: 540px;
        border-radius: 24px;
        margin: 0;
        margin-bottom: 40px;
        flex: 1;

        .loader-roller-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
        }
    }

    .imageview-host,
    video {
        flex: 1;
        position: relative;
        border-radius: 24px;
        overflow: hidden;
        z-index: 20;
        background-color: #e5e5e5;
    }

    video {
        width: 100%;
    }

    .imageview-holder {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        animation: imageview-show .32s;
    }

    .imageview-image {
        width: auto;
        height: 100%;
        display: block;
        margin: 0 auto;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        animation: imageview-show .32s;
    }

    .btn-upload-foto {
        font-size: 10px;
        color: $color-secondary;
        letter-spacing: 0.4px;
        line-height: 1.4;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        height: auto;
        border-radius: 0;
        max-width: 30%;
        width: 100%;
        background-color: transparent;
        padding-top: 3vh;
        margin: 0 5%;
    }

    .btn-upload-foto-result {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 166px;
        height: 166px;
        border-radius: 0;
        background-color: transparent;
        position: absolute;
        top: 50%;
        right: -120px;
        transform: translateY(-50%);
        z-index: 180;

        .download-icon {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            animation: rotate 10s linear infinite;
        }

        .icon-container {
            width: 112px;
            height: 112px;
            box-shadow: 0 0 60px 0 rgba(10, 19, 101, 0.6);
            background-color: $color-secondary;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;

            svg {
                fill: #fff;
                width: 44px;
                height: 44px;
                flex-shrink: 0;
            }
        }
    }

    .btn-refresh-foto-desktop {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 24px;
        line-height: 1;
        letter-spacing: 0.96px;
        text-align: left;
        text-transform: uppercase;
        background-color: transparent;
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(100% + 200px);
        z-index: 40;

        svg {
            width: 32px;
            height: 32px;
            margin-right: 16px;
        }
    }

    .btn-update {
        position: static;
        max-width: none;
        flex-direction: row;
        justify-content: center;
        padding: 0;

        svg {
            flex-shrink: 0;
            width: 32px;
            height: 32px;
            margin: 0;
            margin-right: 16px;
        }

        span {
            font-size: 24px;
            line-height: 1;
            letter-spacing: 0.96px;
            text-align: left;
            color: $color-primary;
        }
    }

    .btn-upload-foto,
    .btn-refresh-foto {
        display: none;
    }

    .btns-container {
        display: flex;
        align-items: flex-start;
        position: relative;
        max-width: 100%;
        z-index: 10;
        margin-top: -5px;
    }

    .icon-container {
        width: 64px;
        height: 64px;
        box-shadow: 0 0 25px 0 rgba(10, 19, 101, 0.4);
        background-color: $color-secondary;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;

        svg {
            fill: #fff;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
        }
    }
}

.creative-error-message-container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 150;
    background-color: #e5e5e5;
    border-radius: 24px;

    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    p {
        text-align: center;
        line-height: 1.4;
        padding: 0 20px;
    }

    .btn-retry {
        font-family: $font-primary;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 226px;
        height: 80px;
        border-radius: 20px;
        position: relative;
        background-color: #4d5ef6;
        padding: 0 10px;
        margin-top: 24px;
    }

    @media (max-width: 900px) {
        .btn-retry {
            font-size: 16px;
            height: 56px;
            border-radius: 16px;
        }
    }
}

.creative-error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.creative-holder-placeholder {
    padding-top: 100%;

    &~.imageview-host {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.wait-video {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    z-index: 5;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: blur(7px);
    }
}

.result-line-mob {
    fill: none;
    position: absolute;
    left: -20px;
    top: -180px;
    width: calc(100% + 40px);
    z-index: -5;
}

@keyframes imageview-show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

button {
    .new-label-upd {
        position: absolute;
        top: 0;
        right: 0;
        height: 14px;
        line-height: 14px;
        padding: 3px 3px 3px 7px;
        border-radius: 0 4px 0 4px;
        background-color: #84ed89;
        text-transform: uppercase;
        font-family: Helvetica, sans-serif;
        font-size: 8px;
        font-weight: bold;
        color: #000;
        border-bottom-left-radius: 12px;
    }
}

.tabs-container {
    display: flex;
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    overflow: auto;
    box-sizing: border-box;
    padding-left: 20px;
    padding-bottom: 10px;
    margin: 0 auto 2px;

    button {
        position: relative;
        width: 70px;
        height: 70px;
        border-radius: 12px;
        overflow: hidden;
        border: 4px solid #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        box-sizing: border-box;
        flex-shrink: 0;
        margin: 0;
        margin-bottom: 16px;
    }

    .active-tab {
        border-color: #7aafff;
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }

    .new-label {
        font-family: $font-primary;
        font-weight: 600;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color-yellow;
        text-transform: uppercase;
        font-size: 16px;
    }
}

.tabs-container .collage__tab_love {
    background-image: url("/assets/img/tabs/tab-icon-love.png");
    background-color: #f0679f;
    background-size: 36px;
    background-position: center;
}

.tab-classic {
    h3 {
        color: #3fb3f9;
    }

    button {
        background-color: #3fb3f9;
    }
}

.tabs-container .collage__tab_labs {
    background-image: url("/assets/img/tabs/tab-icon-labs-v2.png");
    background-color: $color-red;
    background-size: 34px;
    background-position: center;
}

.tabs-container .collage__tab_unite {
    background-image: url("/assets/img/tabs/tab-icon-unite.png");
    background-color: #4d5ef6;
    background-size: 50px;
    background-position: center;
}

.tab-unite-container {
    .tab-unite-content {
        max-width: 400px;
        margin: -60px auto 0;

        h3 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 28px;
            line-height: 1.43;
            text-align: center;
            margin-bottom: 24px;
        }

        a {
            font-weight: 400;
            font-size: 16px;
            color: #fff;
            width: 240px;
            height: 56px;
            text-transform: uppercase;
            border-radius: 16px;
            background-color: #4d5ef6;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            span {
                font-weight: 600;
                margin-left: 8px;
            }
        }
    }

    .bg-image-container {
        display: flex;
        justify-content: center;
        margin-top: -20px;

        img {
            width: 100%;
            max-width: 360px;
        }
    }
}

.collage__tab_classic {
    background-image: url("/assets/img/tabs/tab-icon-classic.png");
}

.loader-video {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.loader-video .item-loader {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    animation: item-loader .9s linear infinite;
    margin: 0 6px;
}

.loader-video .first-item-loader {
    background-color: #1db8ff;
}

.loader-video .second-item-loader {
    background-color: #ffd300;
    animation: item-loader .9s linear infinite;
    animation-delay: .3s;
}

.loader-video .third-item-loader {
    background-color: #fc4700;
    animation: item-loader .9s linear infinite;
    animation-delay: .6s;
}


@keyframes item-loader {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.8, 1.8);
    }
}

.choose-page {
    z-index: 40;
    flex-shrink: 0;
    max-width: 540px;
    width: 100%;
    padding-top: 0;
    margin: 0;

    h2 {
        font-family: $font-primary;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.13;
        text-align: center;
        margin-bottom: 5px;
    }

    p {
        line-height: 1.38;
        text-align: center;
        width: 100%;
    }
}

.choose-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 440px;
    margin: 0 auto;
}

.choose-item-container {
    width: 33%;
    margin-bottom: 1px;
}

.choose-item {
    position: relative;
    padding-top: 100%;

    &.selected {
        border: 3px solid #05b0ff;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}

.updated-at {
    align-self: flex-end;
    grid-column: 1/2;
    grid-row: 3/4;
    // border: dashed 2px #a4e6a7;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='32' ry='32' stroke='%23A4E6A7FF' stroke-width='4' stroke-dasharray='10%2c 18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 32px;
    line-height: 1.67;
    position: absolute;
    left: -30px;
    top: 650px;
    padding: 16px 25px 16px 180px;

    &-date {
        display: block;
        font-family: $font-primary;
        font-weight: 600;
        color: $color-red;
        text-transform: uppercase;
    }

    &.splitB {
        top: 743px;

        .updated-at-date {
            color: $color-primary;
        }
    }
}

.highlight {
    animation: borderPulse 1s infinite ease-out;
}

@keyframes borderPulse {
    0% {
        box-shadow: 0px 0px 0px 0px $color-secondary;
    }

    100% {
        box-shadow: 0px 0px 0px 10px rgba(63, 179, 249, 0);
    }
}

/*labs*/
.btn-experiments {
    background-color: #e434a5;
    padding-top: 2px;
    margin-top: 16px;
}

.labs {
    .main-title {
        color: #e434a5;
        text-align: center;
        margin-bottom: 12px;
    }

    .main-subtitle {
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px;

        span {
            font-weight: bold;
        }
    }

    .btn-upload-foto {
        background-color: #e434a5;
    }

    .btn-get-app {
        animation: borderPulsePink 1s infinite ease-out;
    }

    @keyframes borderPulsePink {
        0% {
            box-shadow: 0px 0px 0px 0px #e434a5;
        }

        100% {
            box-shadow: 0px 0px 0px 10px rgba(63, 179, 249, 0);
        }
    }

    .wr-slider {
        position: relative;
        left: -20px;
        width: calc(100% + 40px);
        margin: 4.6vh auto 8.2vh;

        .slick-dots {
            button {
                background-color: #3fb3f9;
                border-radius: 10px;
            }

            .slick-active {
                width: 30px;

                button {
                    width: 100%;
                    background-color: #3fb3f9;
                }
            }
        }
    }

    .slick-active {
        .slide {
            border-color: #fff;
        }
    }

    .slide {
        display: flex;
        border-radius: 20px;
        border: 5px solid transparent;
        width: 250px;
        height: 250px;
        box-sizing: border-box;
        overflow: hidden;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .updated-at {
        margin-top: 20px;
    }

    .btn-to-toonme {
        font-weight: bold;
        font-size: 16px;
        color: #3eb3f9;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 310px;
        width: 90%;
        height: 48px;
        border-radius: 100px;
        border: solid 2px #3fb3f9;
        background-color: transparent;
        margin: 0 auto;
    }
}

.square {
    position: absolute;
}

.square-big {
    left: 50%;
    top: -20px;
    width: 350px;
    height: 350px;
    transform: rotate(15deg) translateX(-50%);
    opacity: 0.2;
    border-radius: 26px;
    filter: blur(15px);
    background-color: #3fb3f9;
    z-index: -10;
    display: none;
}

.square-med {
    right: 6%;
    top: -120px;
    width: 140px;
    height: 140px;
    transform: rotate(-10deg);
    opacity: 0.08;
    border-radius: 12px;
    background-color: #e434a5;
    z-index: -15;
}

.square-small {
    left: -2%;
    top: -140px;
    width: 90px;
    height: 90px;
    transform: rotate(55deg);
    opacity: 0.15;
    border-radius: 12px;
    filter: blur(5px);
    background-color: #ffdd0d;
    z-index: -15;
}

.tab-custom {
    width: 100%;
    position: relative;
    margin-bottom: 85px;

    &.container {
        padding: 0;
    }

    h3 {
        font-family: $font-logo, "Helvetica";
        font-size: 30px;
        line-height: 1.16;
        text-align: center;
        margin-bottom: 16px;
    }

    p {
        font-size: 16px;
        text-align: center;
        line-height: 1.5;
        margin-bottom: 24px;
    }

    button {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        width: 260px;
        height: 48px;
        border-radius: 100px;
        display: block;
        margin: 0 auto;
    }
}

.tab-lab-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin-top: 20px;
}

.tab-lab {
    h3 {
        color: $color-primary;
    }

    button {
        width: 240px;
        height: 56px;
        border-radius: 16px;
        background-color: $color-red;
        position: relative;
        z-index: 40;
    }
}

.bg-image-container {
    img {
        width: 100%;
    }
}

.tab-love {
    padding: 0;

    .bg-image-container {
        margin-top: -70px;

        img {
            width: 110%;
        }
    }

    &-content {
        margin-top: -60px;
    }

    button {
        background-color: #f0679f;
    }
}

.btns-container_share {

    .btn-upload-foto,
    .btn-upload-foto.download {
        width: 96px;
        margin: 0;
    }
}

.error-page {
    height: 100vh;
    box-sizing: border-box;

    .main-page-title {
        text-align: center;
        margin-bottom: 24px;
    }

    .container {
        position: relative;
    }

    .icon-cat-error {
        width: 100%;
        display: block;
        position: absolute;
        top: -150px;
        margin: 32px auto 0;
    }

    .btn-upload-foto {
        font-size: 24px;
        width: 400px;
        height: 80px;
        border-radius: 20px;
    }
}

.error-content {
    position: relative;
    z-index: 40;
    margin-top: 42vh;

    svg {
        width: 100%;
        display: block;
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 40px;
    }

    .icon-cat-error-mob {
        display: none;
        width: 100%;
        max-width: 360px;
        height: 38vh;
        margin: 3vh auto 0;
    }
}

.btn-guest {
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.96px;
    color: $color-white;
    text-transform: uppercase;
    width: 320px;
    height: 80px;
    border-radius: 20px;
    background-color: $color-secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    margin: 0 auto;

    svg {
        fill: #fff;
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        margin-right: 16px;
    }
}

.btn-upload-v2 {
    font-family: $font-primary;
    font-weight: 600;
    font-size: 28px;
    letter-spacing: 1.12px;
    color: $color-white;
    text-transform: uppercase;
    grid-column: 1/2;
    grid-row: 2/3;
    width: 360px;
    height: 100px;
    border-radius: 24px;
    box-shadow: 0 0 45px 0 rgba(173, 112, 96, 0.5);
    background-color: $color-red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 96px;
    margin-top: 40px;

    .btn-icon-plus {
        width: 28px;
        height: 4px;
        background-color: $color-white;
        border-radius: 4px;
        position: relative;
        margin-right: 16px;

        &:before {
            content: "";
            display: block;
            width: 4px;
            height: 28px;
            border-radius: 4px;
            background-color: $color-white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.hiring-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 50;
    padding-top: 10vh;

    .container {
        max-width: 500px;
    }

    h2 {
        font-size: 28px;
        font-weight: bold;
        text-align: left;
        line-height: 1.43;
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        max-width: 320px;
        margin-top: 3.3vh;

        span {
            font-weight: bold;
        }
    }

    .text-link {
        font-size: 20px;
        font-weight: bold;
        color: $color-secondary;
        margin-top: 5.5vh;
    }

    .btn-show-contacts {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 0.64px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 56px;
        border-radius: 16px;
        background-color: $color-secondary;
        margin: 5.5vh auto 0;
    }

    a {
        font-size: 20px;
        font-weight: bold;
        color: $color-secondary;
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: 1px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #4d5ef6;
        }
    }

    .btn-close {
        position: absolute;
        top: 16px;
        right: 16px;
        display: block;
        padding: 8px;
        background: none;
        border-radius: 100%;

        &:hover,
        &:active {
            background: #eee;
        }

        svg {
            fill: #b6b6b6;
            display: block;
            width: 16px;
            height: 16px;
        }
    }

    img {
        width: 100%;
        max-width: 380px;
        position: fixed;
        bottom: -90px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -5;
    }
}

.contact-page {
    padding-top: 4vh;
    padding-bottom: 1vh;

    .container {
        max-width: 500px;
    }

    h2 {
        font-size: 28px;
        font-weight: bold;
        line-height: 1.43;
        text-align: center;
        margin-bottom: 16px;
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 36px;
    }

    .label {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.33;
        text-align: left;
        color: $color-grey-middle;
        position: absolute;
        left: 0;
        top: -21px;
    }

    .form-container {
        width: 100%;
        height: 100%;
        min-height: 56px;
        position: relative;
        margin-bottom: 36px;

        input {
            font-size: 16px;
            width: 100%;
            min-height: 56px;
            height: 100%;
            border-radius: 16px;
            border: solid 2px $color-grey-middle;
            box-shadow: none;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            padding-left: 16px;
            padding-right: 16px;

            &:focus {
                border-color: $color-green;

                &+label {
                    color: $color-green;
                }
            }
        }
    }

    .btn-close {
        position: absolute;
        top: 16px;
        right: 16px;
        display: block;
        padding: 8px;
        background: none;
        border-radius: 100%;

        &:hover,
        &:active {
            background: #eee;
        }

        svg {
            fill: #b6b6b6;
            display: block;
            width: 16px;
            height: 16px;
        }
    }

    .label-attach {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.64px;
        color: $color-secondary;
        display: flex;

        // align-items: center;
        svg {
            flex-shrink: 0;
            height: 16px;
            width: 16px;
            margin-right: 8px;
        }
    }

    .form-file {
        margin-top: -12px;
        margin-bottom: 8vh;

        input {
            position: absolute;
            z-index: -5;
            outline: 0;
            opacity: 0;
            pointer-events: none;
            user-select: none;
        }
    }

    .form-error-message {
        font-size: 12px;
        color: $color-red;
        position: absolute;
        bottom: -12px;
        left: 0;
    }

    .btns-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .btn-send {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 0.64px;
        color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 56px;
        border-radius: 16px;
        background-color: $color-secondary;
        margin-top: -12px;
    }

    .btn-return {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding-top: 4px;
        padding-bottom: 4px;

        svg {
            height: 16px;
            width: 16px;
            margin-right: 8px;
        }
    }
}

/*feed-page*/
.feed-page {
    overflow: hidden;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;

    .container {
        max-width: 430px;
    }

    .container,
    .wr-slider-images {
        position: relative;
        z-index: 20;
    }

    h4 {
        font-size: 20px;
        font-family: $font-primary;
        font-weight: 600;
        line-height: 1.6;
    }

    .subtitle {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        span {
            font-size: 14px;
            line-height: 1.43;
            flex-shrink: 0;
            margin-right: 23px;
            margin-top: 18px;
        }

        svg {
            width: 120px;
            fill: none;
            flex-shrink: 0;
        }
    }

    .feed-bg {
        max-width: 430px;
        position: absolute;
        top: -335px;
        right: 0;
        z-index: -5;
    }

    .wr-slider-images {
        margin-bottom: 44px;

        .slide img {
            border-radius: 24px;
            width: 240px;
        }

        .slick-list,
        .slick-slider {
            border-radius: 0;
        }
    }

    .steps-container {
        z-index: 10;
    }

    .steps-list {
        width: 65%;
        padding-left: 20px;

        li {
            position: relative;
        }

        span {
            font-size: 14px;
            line-height: 1.43;

            span {
                font-weight: 600;
            }
        }

        svg {
            fill: none;
        }

        .step1 {
            .icon-step1 {
                position: absolute;
                top: 8px;
                left: -44px;
                width: 32px;
            }

            .icon-step1-1 {
                margin-top: -12px;
                // float: right;
                width: 178px;
                margin-left: 12px;
            }
        }

        .step2 {
            margin-top: -8px;

            svg {
                width: 210px;
                margin-left: 14px;
                margin-top: -2px;
            }
        }

        .step3 {
            svg {
                width: 114px;
                float: right;
                margin-top: -10px;
            }
        }
    }

    button {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 16px;
        color: $color-white;
        letter-spacing: 0.64px;
        text-transform: uppercase;
        width: 240px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        background-color: $color-secondary;
        margin: 0 auto 16px;

        svg {
            width: 14px;
            margin-left: 12px;
            margin-right: 6px;
        }
    }

    .feed-info {
        font-size: 12px;
        font-style: italic;
        line-height: 1.33;
        text-align: center;
    }
}

&.app-webads-enabled {
    .collage-page {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        overflow: hidden;
        position: relative;
        padding-bottom: 0;
        padding-top: 2vh;
    }

    .collage-page header {
        margin-bottom: 2vh;
    }

    .banner-footer {
        display: block;
        flex-shrink: 0;
        height: 120px;
        position: relative;
        z-index: 50;
    }
}

.-show-popup {
    overflow: hidden;
}

.modal-container-body-select {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: rgba(45, 49, 52, 0.7);

    .modal {
        padding: 72px 72px 62px 72px;
    }

    .btns-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 30px;
    }

    .btn-select-style {
        color: #fff;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 49%;
        height: 80px;
        flex-grow: 0;
        flex-shrink: 0;
        border-radius: 20px;
        padding-top: 4px;
    }

    .btn-select-head {
        background-color: #f6704d;
    }

    .btn-select-body {
        background-color: #4d5ef6;
    }

    .btn-cancel {
        color: #b6b6b6;
        background: none;
        display: flex;
        padding: 10px;
        margin: 0 auto;
    }
}

.body-chooser-container {
    position: relative;
    z-index: 200;
    max-width: 540px;
    width: calc(100% + 40px);
    margin-left: -20px;
}

.modal-develop {
    user-select: text;

    .modal {
        padding: 20px;
        width: 100%;
        height: 100%;
        border-radius: 0;
        overflow: auto;

        .info-block {
            margin-bottom: 12px;
        }

        .main-text {
            font-size: 14px;
            font-weight: 600;
            text-align: left;
            margin-bottom: 6px;
        }

        .additional-text {
            font-size: 12px;
            text-align: left;
            line-height: 1;
            padding-left: 20px;
            margin: 0 auto 6px;
        }
    }

    .btn-cancel {
        position: absolute;
        top: 10px;
        right: 10px;

        svg {
            margin: 0;
        }
    }

    textarea {
        font-size: 12px;
        resize: none;
        width: 100%;
        height: 100%;
        max-height: 60px;
        margin-bottom: 16px;
    }

    .btn-copy {
        background-color: #4d5ef6;
        color: #fff;
        height: 80px;
        padding: 0 33px;

        @media all and (max-width: 900px) {
            height: 56px;
            padding: 0 16px;
        }
    }

    .btns-container {
        justify-content: center;
        margin-bottom: 0;
    }
}

.body-chooser-creative {
    position: relative;
    width: calc(100% - 40px);
    margin: 0 auto 24px;

    img {
        object-fit: contain;
        width: 100%;
        border-radius: 32px;
    }

    button {
        position: absolute;
        z-index: 30;
        right: 10px;
        bottom: 10px;
        width: 56px;
        height: 56px;
        box-shadow: 0 0 25px 0 rgba(45, 49, 52, .4);
        background-color: #F6704D;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 24px;
        }
    }
}

.btns-template-container {
    display: flex;
    overflow: auto;
    box-sizing: border-box;
    padding-bottom: 6px;
    padding-left: 20px;

    &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #cdcdcd;
    }

    button {
        width: 54px;
        height: 54px;
        border-radius: 12px;
        margin-right: 8px;
        background-size: cover;
        flex-shrink: 0;
        overflow: hidden;
    }

    .active {
        border: solid 3px #4d5ef6;
    }

    .btn-switch-to-female {
        background-image: url(/assets/images/bodychooser/female.png);
    }

    .btn-switch-to-male {
        background-image: url(/assets/images/bodychooser/male.png);
    }
}

.body-chooser-loader {
    position: relative;
    background-color: #e5e5e5;
    border-radius: 24px;
    overflow: hidden;
    z-index: 20;
    width: calc(100% - 40px);
    margin: 0 auto 24px;

    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    .loader-roller-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.btn-toggle-styles {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #F6704D;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    line-height: 1.4;
}

.btn-choice-gender {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-color: rgba(0, 0, 0, .75);

    svg {
        position: relative;
        z-index: 2;
        width: 12px;
        flex-shrink: 0;
        margin-bottom: 4px;
    }

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
    }

    &-title {
        font-family: "Helvetica-Bold", sans-serif;
        font-size: 10px;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        z-index: 2;

        span {
            color: #fff;
        }
    }
}

.btn-switch-to-male {
    background-image: url("/assets/images/vectorbodychooser/male.png");

    .btn-choice-gender-title {
        color: #4fadf8;
    }

    svg {
        fill: #4fadf8;
    }
}

.btn-switch-to-female {
    background-image: url("/assets/images/vectorbodychooser/female.png");

    .btn-choice-gender-title {
        color: #f0679f;
    }

    svg {
        fill: #f0679f;
    }
}

.template-name {
    font-family: $font-primary;
    font-weight: 600;
    font-size: 24px;
    color: #7aafff;
    line-height: 1;
    text-transform: capitalize;
    position: absolute;
    top: -40px;
    left: 198px;

    @media all and (max-width: 1200px) {
        left: 122px;
    }

    @media all and (max-width: 900px) {
        top: 62px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;
    }
}

.body-chooser-creative .btn-choose-head {
    width: 86px;
    height: 86px;
    border: solid 3px #05b0ff;
    background-color: transparent;
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    top: 13px;
    right: 13px;
    z-index: 25;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    span {
        font-size: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: solid 3px #05b0ff;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.banner-ad {
    background-color: #fecf0d;
    height: 120px;
    position: relative;
    z-index: 100;

    @media all and (max-width: 900px) {
        height: 56px;
        margin-bottom: 12px;
    }

    @media all and (max-width: 430px) {
        margin-bottom: 0;
    }

    .container {
        display: flex;
        align-items: center;
        height: 100%;
        max-width: 1200px;

        @media all and (max-width: 430px) {
            padding: 0;
        }
    }

    &-image {
        width: 194px;
        top: 14px;
        position: relative;
        margin-right: 4.5vw;
        margin-left: 1vw;

        @media all and (max-width: 1100px) {
            margin-right: 1.5vw;
            margin-left: 0;
        }

        @media all and (max-width: 900px) {
            width: 92px;
            top: 7px;
            left: -16px;
        }

        @media all and (max-width: 430px) {
            left: 0;
        }
    }

    &-title {
        font-size: 32px;
        font-weight: 600;
        margin-right: 2.5vw;

        &-mob {
            display: none;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            flex-grow: 1;
            text-align: center;
            padding-right: 12vw;

            @media all and (max-width: 900px) {
                display: block;
            }

            @media all and (max-width: 430px) {
                padding: 0;
                position: relative;
                left: -10px;
                font-size: 14px;
            }
        }

        @media all and (max-width: 1100px) {
            font-size: 24px;
        }

        @media all and (max-width: 900px) {
            display: none;
        }
    }

    &-text {
        font-size: 24px;

        @media all and (max-width: 1100px) {
            font-size: 20px;
        }

        @media all and (max-width: 900px) {
            display: none;
        }
    }
}

.btns-market-middle-mb {
    margin-bottom: 32px;
}

.btns-market-small-container {
    max-width: 560px;
    margin: 0 auto 80px;
    justify-content: flex-end;
}

.result-loader {
    width: 400px;
    height: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        position: relative;
        z-index: 10;
        font-size: 24px;
        line-height: 1.67;
    }

    .loader-roller-wrapper {
        z-index: 10;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 160px;
        filter: blur(85px);
        background-color: #fff;
        width: 100%;
        height: 100%;
    }
}

@keyframes showTabs {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.subtabs-container {
    display: none;
    justify-content: center;
    position: absolute;
    bottom: -30px;
    z-index: 20;
    overflow: hidden;
    width: 100%;
    margin-bottom: -10px;
    height: 88px;
    animation: showTabs .5s ease;

    @media all and (max-width: 900px) {
        display: flex;
    }
}

.subtabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    overflow-x: auto;
    padding: 24px 16px 16px;

    &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 1px;
        height: 1px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: transparent;
    }
}

.subtab {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-size: cover;

    &.active {
        position: relative;
        box-shadow: 0 0 16px 0 #4d5ef6;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: solid 3px #4d5ef6;
            box-sizing: border-box;
            border-radius: 50%;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
        }
    }

    .label {
        font-size: 5px;
        font-weight: bold;
        color: #000;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        box-shadow: 0 0 8px 0 #53e759;
        background-color: #73ff79;
        position: absolute;
        top: 0;
        right: 0;
    }
}

@media all and (max-width: 1330px) {
    header {
        padding: 0 20px;
    }

    .main-page-title-container {
        padding-left: 0;
    }

    .btn-upload-v2 {
        margin-left: 0;
    }

    .main-page-content {
        .wr-slider-images {
            height: max-content;
            // position: static;
        }

        .updated-at {
            position: relative;
            margin-top: 40px;
        }
    }

    .main-page-labs-info {
        width: 530px;
    }

    .btn-upload-foto-desktop {
        width: 180px;
        height: 180px;

        .btn-icon {
            width: 120px;
            height: 120px;
        }
    }

    .wr-slider-logos,
    .title-contacts-form,
    .footer {
        padding-left: 0;
    }

    .instagram {
        padding-left: 0;
        padding-right: 0;
    }
}

@media all and (max-width: 1200px) {
    .collage-page-content {
        padding-left: 20px;
    }

    .collage-page .creative-holder-container {
        max-width: 400px;
    }

    .collage-page-content .tabs-container {
        max-height: 470px;
    }
}

@media all and (max-width: 1060px) {
    .main-page-content {
        grid-template-columns: auto 460px;

        .updated-at {
            text-align: center;
            grid-column: 1/3;
            grid-row: 5/6;
            line-height: 1.5;
            left: 0;
            padding-left: 25px;
            margin-top: 160px;
        }
    }

    .main-page-labs-info {
        width: 460px;
    }

    .main-page-labs-container {
        grid-row: 4/5;
    }

    .main-page-love-container {
        grid-row: 3/4;
    }

    .main-page-subtitle {
        max-width: 75%;
    }
}

@media all and (max-width: 970px) {
    .main-page-labs-container {
        picture {
            width: 40%;
        }

        img {
            width: 100%;
        }
    }
}

@media all and (max-height: 1020px) {
    &.app-webads-enabled {
        .tabs-container button {
            width: 60px;
            height: 60px;
            border-radius: 10px;
            margin-bottom: 6px;
        }

        .collage-page .creative-holder {
            margin-bottom: 2vh;
        }
    }
}

.btn-spacer {
    display: none;
}

@media all and (max-width: 900px) {
    .btn-spacer {
        display: block;
    }

    .main-page {
        .btn-upload-foto {
            display: block;
            max-width: 420px;
            width: calc(100% - 40px);
            margin-top: 24px;
            margin-bottom: 0;
        }
    }

    .collage-page {
        padding-bottom: 24px;
    }

    .collage-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        height: 100%;
        overflow: hidden;

        &-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding-left: 20px;

            .line-result-mob {
                display: block;
            }

            .tabs-container {
                flex-direction: row;
                position: relative;
                width: calc(100% + 40px);
                max-width: 450px;
                padding-left: 20px;
                padding-bottom: 4px;
                margin: 0 auto 36px;

                button {
                    width: 54px;
                    height: 54px;
                    border-width: 3px;
                    margin: 0;
                    margin-right: 5px;
                }
            }
        }

        &-wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        .line-result {
            display: none;
        }

        .creative-holder {
            max-width: 450px;
            margin: 0 auto 4vh;

            &--subtabs {
                margin: 0 auto;
            }

            &-container {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                margin: 0 auto;
            }
        }

        .tab-custom {
            margin: 0 auto;
        }

        .btn-update {
            position: relative;
            max-width: 30%;
            flex-direction: column;
            justify-content: center;
            padding-top: 3vh;

            svg {
                flex-shrink: 0;
                width: 24px;
                height: 24px;
                margin: 0;
                margin-bottom: 8px;
            }

            span {
                font-size: 10px;
                line-height: 1.4;
                letter-spacing: 0.4px;
                text-align: center;
                color: $color-grey-dark;
            }
        }

        .btn-upload-foto,
        .btn-refresh-foto {
            display: flex;
        }

        .btn-upload-foto-result,
        .btn-refresh-foto-desktop {
            display: none;
        }

        header {
            margin-bottom: 24px;
        }

        .btns-container-subtabs {
            // flex-grow: 1;
            align-items: flex-end;
            justify-content: center;
            width: 100%;
            margin-top: 44px;

            &.isPro {
                margin-top: 55px;
            }

            .btn-update {
                flex: 1 0;
                max-width: 160px;
                height: 40px;
                border-radius: 24px;
                position: static;
                border: solid 1px #000;
                padding: 0 4px;
                margin-right: 16px;

                svg {
                    display: none;
                }

                span {
                    font-size: 13px;
                    line-height: 1.38;
                    letter-spacing: 1.3px;
                    color: #000;
                    text-align: center;
                }
            }

            .btn-upload-foto {
                font-size: 13px;
                line-height: 1.38;
                letter-spacing: 1.3px;
                text-transform: uppercase;
                flex: 1 0;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 160px;
                width: 100%;
                height: 40px;
                border-radius: 40px;
                box-shadow: 0 0 24px 0 rgba(77, 94, 246, 0.5);
                background-color: #4d5ef6;
                text-align: center;
                padding: 0 4px;
                margin: 0;

                .icon-container {
                    display: none;
                }
            }
        }

        .btns-container-subtabs-empty {
            // margin-top: 84px;
        }
    }

    .choose-page {
        padding-top: 16px;
        margin: 0 auto;
    }

    .btn-upload-v2 {
        font-size: 14px;
        width: 200px;
        height: 48px;
        border-radius: 16px;
        grid-row: 3/4;
        margin: 40px auto 0;

        .btn-icon-plus {
            width: 14px;
            height: 2px;
            background-color: #fff;
            border-radius: 4px;
            position: relative;
            margin-right: 8px;

            &:before {
                width: 2px;
                height: 14px;
            }
        }
    }

    .main-page-content .wr-slider-images .slide {
        justify-content: center;
    }

    .header-main-content {
        display: flex;
        align-items: center;
    }

    .header-other-content {
        display: none;
    }

    .logo {
        font-size: 20px;
    }

    .btn-photo-lab {
        font-size: 14px;
    }

    .main-page-content {
        grid-template-columns: 100%;
        margin-bottom: 90px;

        .wr-slider-images {
            height: auto;
            grid-column: 1/2;
            grid-row: 2/3;
            position: relative;
            left: -20px;
            width: calc(100% + 40px);
            margin-bottom: 0;

            .slide img {
                max-width: 460px;
                margin: 0 auto;
                display: block;
            }

            .slick-dots {
                bottom: 4px;
            }

            .square {
                width: 102px;
                height: 102px;
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23A4E6A7FF' stroke-width='4' stroke-dasharray='10%2c 18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
                border-radius: 16px;
                border-radius: 24px;
                bottom: 24px;
                right: 15px;
            }
        }

        .updated-at {
            font-size: 14px;
            grid-column: 1/2;
            grid-row: 4/5;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23A4E6A7FF' stroke-width='4' stroke-dasharray='10%2c 18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            border-radius: 16px;
            padding: 16px 25px;
            margin-top: 56px;

            &-title {
                margin-bottom: 4px;
            }
        }
    }

    .main-page-subtitle {
        max-width: 100%;
    }

    .main-page-title {
        font-size: 32px;
        line-height: 1.44;
        text-align: center;

        span:before {
            height: 38px;
        }
    }

    .main-page-subtitle {
        font-size: 16px;
        text-align: center;
        margin-top: 16px;
        margin-bottom: 24px;
    }

    .wr-slider-images {
        margin-bottom: 16px;
    }

    .wr-slider-logos {
        width: calc(100% + 40px);
        position: relative;
        left: -20px;
        margin-bottom: 62px;

        a {
            width: 104px;
            margin: 0 auto;
        }
    }

    .posts-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 16px;

        a {
            border-radius: 12px;
            width: 32%;
            margin-bottom: 6px;
        }
    }

    .btns-market-container {
        flex-direction: column;
        margin-bottom: 40px;

        .btn-store {
            font-size: 16px;
            width: 240px;
            height: 56px;
            border-radius: 16px;
            padding-top: 3px;

            svg {
                height: 24px;
                width: 24px;
                fill: $color-white;
                top: -1px;
                margin-right: 12px;
            }

            &:last-child {
                margin-top: 16px;
            }
        }

        .btn-store-huawei {
            font-size: 16px;
            letter-spacing: 0.64px;
            margin-left: -5px;

            svg {
                width: 28px;
                margin-right: 8px;
            }
        }

        .btn-store-apple svg {
            top: -3px;
        }
    }

    .btns-market-middle-mb {
        margin-bottom: 16px;
    }

    .instagram {
        margin-bottom: 34px;

        .user-info {
            display: none;
        }

        .post {
            margin-bottom: 6px;
        }

        .post-image {
            margin-bottom: 0;
        }
    }

    .title-contacts-form {
        font-size: 16px;
        text-align: center;
        padding-right: 0;
        margin-bottom: 40px;
    }

    .footer {
        padding-bottom: 0;
    }

    .btn-contacts-form-footer {
        display: block;
    }

    .btn-privacy {
        font-size: 16px;
    }

    .main-page-labs-container {
        font-size: 16px;
        text-align: center;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        position: relative;
        grid-row: 5/6;
        padding: 0;
        margin-top: 40px;
        margin-bottom: 0;

        picture {
            width: 104%;
        }

        .main-page-title {
            max-width: 290px;
            margin: 0 auto 24px;
        }

        span {
            text-align: center;
        }
    }

    .main-page-labs-info {
        position: absolute;
        top: 90px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;

        .btn-go-labs {
            font-size: 16px;
            width: 240px;
            height: 56px;
            border-radius: 16px;
            margin: 0 auto;
        }
    }

    .main-page-love-container {
        margin-top: 40px;

        .main-page-title {
            margin-bottom: 24px;
        }
    }

    .btn-upload-foto-desktop {
        width: 240px;
        height: 56px;
        border-radius: 16px;
        background-color: $color-secondary;
        position: static;
        margin: 24px auto 0;

        svg,
        .btn-icon {
            display: none;
        }

        &-title {
            display: block;
            font-family: $font-primary;
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.64px;
            color: #fff;
            text-transform: uppercase;
        }
    }

    .instagram-page {
        .container {
            display: block;
        }

        .user-post {
            width: 100%;
            max-width: 450px;
            margin: 0 auto 40px;
        }

        .wr-slider-images {
            position: relative;
            left: -20px;
            width: calc(100% + 40px);

            .slide {
                padding: 20px 20px 0;
            }
        }
    }

    .error-page {
        height: auto;

        .icon-cat-error {
            display: none;
        }

        .btn-upload-foto {
            font-size: 16px;
            width: 240px;
            height: 56px;
            border-radius: 16px;
        }
    }

    .error-content {
        margin: 0;

        .icon-cat-error-mob {
            display: block;
        }
    }

    .btn-guest {
        font-size: 16px;
        letter-spacing: 0.64px;
        width: 240px;
        height: 56px;
        border-radius: 16px;
        padding: 0 10px;

        svg {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
    }

    .main-page-unite-container {
        flex-direction: column;

        picture {
            width: 100%;
            max-width: 460px;
        }
    }

    .main-page-unite-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        margin-top: -30px;

        .main-page-title {
            margin-bottom: 24px;
            max-width: 100%;
        }

        .btn-go-unite {
            font-size: 16px;
            width: 240px;
            height: 56px;
            border-radius: 16px;
            margin: 0 auto;
        }
    }

    .tab-unite-container {
        .bg-image-container {
            display: flex;
            justify-content: center;
            margin-top: -30px;

            img {
                max-width: 400px;
            }
        }

        .tab-unite-content {
            margin: -60px auto 0;
        }
    }

    .tabs-container .collage__tab_unite {
        background-size: 38px;
    }
}

@media all and (max-height: 640px) {
    .main-page {
        .line-main-mob {
            display: block;
        }

        .line-main {
            display: none;
        }
    }

    .collage-page {
        padding-bottom: 16px;

        .creative-holder {
            // max-width: 280px;
        }
    }

    .collage-page header {
        margin-bottom: 16px;
    }

    .main-page {
        .line-main {
            display: none;
        }

        .line-main-mob {
            display: block;
        }
    }

    .collage-page-content .tabs-container {
        margin: 0 auto 36px;
    }

    .template-name {
        font-size: 20px;
        top: 66px;
    }
}

@media all and (max-height: 600px) {
    .feed-page {
        h4 {
            font-size: 18px;
            line-height: 1.4;
        }

        .subtitle {
            margin-bottom: 0;
        }

        .wr-slider-images {
            margin-bottom: 8px;
        }

        .steps-list .step3 svg {
            position: relative;
            z-index: 20;
        }

        button,
        .feed-info {
            position: relative;
            top: -20px;
        }
    }

    .collage-page {
        padding-bottom: 16px;
    }

    .collage-page-content .tabs-container {
        margin: 0 auto 36px;
    }

    .template-name {
        font-size: 20px;
        top: 66px;
    }
}

@media all and (max-width: 650px) {
    .modal-container-body-select {
        .modal {
            padding: 42px 20px 52px;
        }

        .btns-container {
            flex-direction: column;
        }

        .btn-select-style {
            width: 100%;
            height: 60px;
            max-width: 360px;
            margin-top: 20px;
        }
    }
}

@media all and (max-width: 530px) {
    &.app-webads-enabled {
        .collage-page-content {
            margin-top: 0;
            margin-bottom: 20px;

            .line-result-mob {
                bottom: -100px;
            }
        }

        .collage-page {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 0;

            .creative-holder-container {
                display: flex;
            }

            header {
                align-items: center;
            }

            .creative-holder {
                margin-bottom: 0;
            }

            .btns-container {
                flex-direction: column;
                flex-grow: 1;
                justify-content: flex-start;
                align-items: center;
                width: 25%;
                flex-shrink: 0;
                position: static;
                margin-left: 20px;
            }

            .btn-update {
                position: static;
                max-width: 100%;
                padding: 0;
                margin: 0;
            }

            .btn-upload-foto {
                max-width: 100%;
                padding: 0;
                margin: 20px 0;
            }
        }

        .banner-footer {
            display: block;
            flex-shrink: 0;
            //background: red;
            height: 120px;
            position: relative;
            z-index: 50;
        }
    }
}

@media all and (max-height: 620px) {
    &.app-webads-enabled {
        .collage-page-content {
            margin-bottom: 6px;
        }

        .collage-page {
            justify-content: flex-start;

            .btn-upload-foto {
                margin: 10px 0;
            }

            .icon-container {
                width: 46px;
                height: 46px;
                margin-bottom: 4px;
            }

            .btn-update svg {
                width: 18px;
                height: 18px;
                margin-bottom: 4px;
            }

            .creative-holder {
                // max-width: 160px;
            }
        }
    }
}

@media all and (max-width: 460px) {
    .main-page-labs-container picture {
        width: 120%;
    }

    .main-page-labs-info {
        width: 100%;
    }

    .main-page-content {
        margin-bottom: 50px;

        .wr-slider-images picture {
            width: 80%;
        }
    }

    button .new-label-upd {
        padding: 2px 3px 2px 4px;
        font-size: 7px;
    }

    .main-page-content .wr-slider-images .slide img {
        max-width: 300px;
    }

    .main-page-subtitle {
        margin-top: 12px;
        margin-bottom: 16px;
    }
}

@media all and (max-width: 430px) {
    header {
        margin-bottom: 16px;
    }

    .main-page-title {
        line-height: 1.3;
    }

    .main-page-subtitle {
        position: relative;
        z-index: 10;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .main-page .btn-upload-foto {
        margin-top: 16px;
    }
}

@media all and (max-width: 410px) {
    .feed-page .subtitle {
        margin-top: -30px;
    }
}

@media all and (min-width: 400px) and (max-height: 720px) {
    .feed-page .wr-slider-images .slide img {
        border-radius: 20px;
        width: 200px;
    }

    .feed-page {
        padding-bottom: 0
    }

    .feed-page h4 {
        max-width: 360px;
    }

    .feed-page .subtitle {
        margin-top: -30px;
        margin-bottom: 0;
    }

    .feed-page .wr-slider-images {
        margin-bottom: 10px;
    }

    .feed-page button,
    .feed-page .feed-info {
        position: relative;
        top: -20px;
    }

    .feed-page .steps-list .step3 svg {
        position: relative;
        z-index: 10;
    }
}

@media all and (min-width: 900px) and (max-height: 640px) {
    .collage-page-content .tabs-container {
        max-height: 400px;
        margin: 0 20px;
    }

    .template-name {
        display: none;
    }
}

@media all and (max-width: 380px) {
    .feed-page .wr-slider-images .slide img {
        border-radius: 20px;
        width: 200px;
    }
}

@media all and (max-width: 380px) and (max-height: 780px) {
    .feed-page .steps-list {
        padding-left: 0;
    }

    .feed-page .steps-list .step1 .icon-step1 {
        top: 9px;
        left: -30px;
        width: 26px;
    }

    .feed-page .feed-bg {
        max-width: 380px;
        top: -275px;
    }

    .feed-page .wr-slider-images {
        margin-bottom: 16px;
    }

    .feed-page .wr-slider-images .slide img {
        border-radius: 20px;
        width: 160px;
    }
}

@media all and (max-width: 370px) {
    .main-page-labs-info {
        bottom: -30px;
    }

    .main-page .btn-upload-foto {
        margin-top: 16px;
    }

    .main-page-content .wr-slider-images picture {
        width: 70%;
    }

    &.app-webads-enabled {
        .collage-page .creative-holder-container {
            max-width: 280px;
        }
    }
}

@media all and (max-width: 340px) {
    .feed-page .subtitle span {
        margin-right: 12px;
    }
}

.banner-ad.banner-tooncoin {
    height: 88px;
    cursor: pointer;
    background-color: #121212;

    @media all and (max-width: 1200px) {
        max-height: 88px;
        height: 100%;
    }
}

.banner-ad.banner-tooncoin .container {
    justify-content: center;
}

.banner-ad.banner-tooncoin .container img {
    height: 100%;

    @media all and (max-width: 1200px) {
        width: 100%;
        object-fit: contain;
    }
}

.tooncoin__button_back {
    text-transform: uppercase;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: transparent;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.64px;
    text-align: left;
    color: #3e4552;
}

.tooncoin__button_back svg {
    margin-right: 8px;
}

.tooncoin__title span {
    font-family: $font-primary;
    font-weight: 800;
}

@at-root .app-type--webview {
    .tooncoin {
        padding-top: 0;
    }

    .collage-page {
        padding-top: 56px;

        @media (max-width: 900px) {
            padding-top: 16px;
        }
    }

    .main-page-content {
        margin-bottom: 0;
    }

    .main-page {
        min-height: 100vh;
        box-sizing: border-box;
        padding-bottom: 12px;
    }
}

@media (max-width: 850px) {
    .tooncoin__paragraph {
        margin-bottom: 110px;
    }
}

@media (max-width: 620px) {
    .tooncoin__title {
        font-size: 45px;
    }
}

@media (max-width: 580px) {
    .tooncoin__title {
        font-size: 40px;
    }
}

@media (max-width: 520px) {
    .tooncoin__title {
        font-size: 35px;
    }
}

@media (max-width: 460px) {
    .tooncoin__title {
        font-size: 30px;
    }
}

@media (max-width: 450px) {
    .tooncoin__button {
        font-size: 18px;
    }
}

@media (max-width: 430px) {
    .tooncoin__button {
        font-size: 16px;
    }
}

@media (max-width: 420px) {
    .tooncoin__paragraph {
        margin-bottom: 75px;
    }
}

@media (max-width: 400px) {
    .tooncoin__title {
        font-size: 25px;
    }
}

@media (max-width: 340px) {
    .tooncoin__title {
        font-size: 20px;
    }
}

@media (max-width: 280px) {
    .tooncoin__title {
        font-size: 18px;
    }
}