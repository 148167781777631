// -- privacy
// -- частично стянуто с https://toonme.app/privacy.html

.privacy__wrapper {
  width: 90%;
  max-width: 62rem;
  margin: 30px auto;

  p {
    text-align: left !important;
  }

  h1 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.privacy__logo {
  font-family: 'Chewy';
  font-size: 30px;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1px;
  color: #fff;
  border-radius: 44px;
  background-color: #3fb3f9;
  display: inline-block;
  padding: 7px 17px;
}

.privacy__page_title {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.33;
  color: #3fb3f9;
  margin-bottom: 33px;
}

.privacy__paragraph {
  width: 100%;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 300;
  letter-spacing: .03rem;
  margin-bottom: 2rem !important;
}

.privacy__list {
  list-style: disc;
  line-height: 150%;
  margin: 0 0 2rem 2rem;
}

.privacy__paragraph span {
  display: block;
  text-transform: uppercase;
  margin-bottom: 0.4rem;
}

.privacy__paragraph a {
  color: #3fb3f9;
  text-decoration: underline
}

.privacy__title {
  font-weight: 700;
}

@media (max-width: 700px) {
  .policy__wrapper {
    font-size: 14px
  }
}

@media (max-width: 500px) {
  .policy__wrapper {
    font-size: 13px
  }
}

@media (max-width: 400px) {
  .policy__wrapper {
    font-size: 12px
  }
}

@media (max-width: 370px) {
  .policy__wrapper {
    font-size: 11px
  }
}

.watermark-image {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.app-toast-top {
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  z-index: 65535;
  padding: 12px 16px;
  background-color: rgba(255, 255, 255, .8);
  color: black;
  font-weight: 700;
  border-radius: 6px;
  box-shadow: 0 2px 14px 2px rgba(0, 0, 0, .25);
  text-align: center;
}

// ---

.collage__tab_disney5276 { background-image: url("/assets/img/tabs/disney5276.jpg?1"); }
.collage__tab_disney5277 { background-image: url("/assets/img/tabs/disney5277.jpg?1"); }
.collage__tab_barbie5278 { background-image: url("/assets/img/tabs/barbie5278.jpg?1"); }
.collage__tab_barbie5279 { background-image: url("/assets/img/tabs/barbie5279.jpg?1"); }
.collage__tab_komuk3111 { background-image: url("/assets/img/tabs/tab-icon-komuk3111.jpg?1"); }
.collage__tab_carlafuentesart { background-image: url("/assets/img/tabs/tab-icon-carlafuentesart.jpg?1"); }
.collage__tab_scarydolls { background-image: url("/assets/img/tabs/tab-icon-scarydolls.jpg?1"); }
.collage__tab_popart { background-image: url("/assets/img/tabs/tab-icon-popart.png?1"); }
.collage__tab_simpsons { background-image: url("/assets/img/tabs/tab-icon-1.jpg?1"); }
.collage__tab_disney_with_5273,
.collage__tab_disney { background-image: url("/assets/img/tabs/tab-icon-2.jpg?1"); }
.collage__tab_disney_2d { background-image: url("/assets/img/tabs/tab-icon-6.jpg?1"); }
.collage__tab_disney_collage { background-image: url("/assets/img/tabs/tab-icon-5266.jpg?1"); }
.collage__tab_archer { background-image: url("/assets/img/tabs/tab-icon-3.jpg?1"); }
.collage__tab_vector { background-image: url("/assets/img/tabs/tab-icon-4.jpg?1"); }
.collage__tab_collage { background-image: url("/assets/img/tabs/tab-icon-5.png?1"); }
.collage__tab_caricature { background-image: url("/assets/img/tabs/tab-icon-caricature.png?1"); }
.collage__tab_caricature_2 { background-image: url("/assets/img/tabs/tab-icon-caricature_2.jpg?1"); }
.collage__tab_caricature_3 { background-image: url("/assets/img/tabs/tab-icon-caricature_3.jpg?1"); }
.collage__tab_t4532,
.collage__tab_t4532m,
.collage__tab_glamour { background-image: url("/assets/img/tabs/tab-icon-10.png?1"); }
.collage__tab_barbie { background-image: url("/assets/img/tabs/tab-icon-barbie.jpg?1"); }
.collage__tab_cartoon,
.collage__tab_cartoon2 { background-image: url("/assets/img/tabs/tab-icon-cartoon.jpg?1"); }
.collage__tab_vectorpsp { background-image: url("/assets/img/tabs/tab-icon-vectorpsp.jpg?1"); }
.collage__tab_cartoon_anim { background-image: url("/assets/img/tabs/tab-icon-cartoon_anim.jpg?1"); }
.collage__tab_cartoon_vector_body { background-image: url("/assets/images/bodychooser/bodies/5101m.png?1"); }
.collage__tab_voila { background-image: url("/assets/img/tabs/voila.jpg?1"); }
.collage__tab_tab210622 { background-image: url("/assets/img/tabs/tab210622.jpg?1"); }
.collage__tab_tab210623 { background-image: url("/assets/img/tabs/tab210623.jpg?1"); }
.collage__tab_disney5336 { background-image: url("/assets/img/tabs/disney5336.jpg?1"); }
.collage__tab_disney2dmix { background-image: url("/assets/img/tabs/disney2dmix.jpg?1"); }
.collage__tab_disney5357 { background-image: url("/assets/img/tabs/disney5357.jpg?1"); }
.collage__tab_disney5361 { background-image: url("/assets/img/tabs/disney5361.jpg?1"); }
.collage__tab_tab5378 { background-image: url("/assets/img/tabs/tab5378.jpg?1"); }
.collage__tab_tab5379 { background-image: url("/assets/img/tabs/tab5379.jpg?1"); }
.collage__tab_tab5390 { background-image: url("/assets/img/tabs/tab5390.jpg?1"); }
.collage__tab_popart5408 { background-image: url("/assets/img/tabs/popart5408.jpg?1"); }
.collage__tab_simpson5409 { background-image: url("/assets/img/tabs/simpson5409.jpg?1"); }
.collage__tab_popart5417 { background-image: url("/assets/img/tabs/popart5408.jpg?1"); }
.collage__tab_simpson5418 { background-image: url("/assets/img/tabs/simpson5409.jpg?1"); }
.collage__tab_tas { background-image: url("/assets/img/tabs/tas.jpg?1"); }
.collage__tab_genderswap_f { background-image: url("/assets/img/tabs/genderswap_f.jpg?1"); }
.collage__tab_genderswap_m { background-image: url("/assets/img/tabs/genderswap_m.jpg?1"); }
.collage__tab_strokes { background-image: url("/assets/img/tabs/strokes.jpg?1"); }
.collage__tab_t5660p5687 { background-image: url("/assets/img/tabs/t5660p5687.jpg?1"); }
.collage__tab_tas2 { background-image: url("/assets/img/tabs/tas2_5570.jpg?1"); }
.collage__tab_tas3 { background-image: url("/assets/img/tabs/tas3.jpg?1"); }
.collage__tab_stickers_collage { background-image: url("/assets/img/tabs/stickers_collage.jpg?1"); }
.collage__tab_halloween_collage { background-image: url("/assets/img/tabs/halloween_collage.jpg?1"); }
.collage__tab_halloween2021 { background-image: url("/assets/img/tabs/halloween2021.jpg?1"); }
.collage__tab_simpsons2 { background-image: url("/assets/img/tabs/simpsons2.jpg?1"); }
.collage__tab_disney6151 { background-image: url("/assets/img/tabs/disney6151.jpeg?1"); }
.collage__tab_nn_anime_gan { background-image: url("/assets/img/tabs/nn_anime_gan.jpg?1"); }
.collage__tab_tab_6750 { background-image: url("/assets/img/tabs/tab_6750.jpg?1"); }
.collage__tab_tab6796 { background-image: url("/assets/img/tabs/tab6796.jpg?1"); }
.collage__tab_tab6756 { background-image: url("/assets/img/tabs/tab6756.jpg?1"); }
.collage__tab_cartoon3 { background-image: url("/assets/img/tabs/tab6756.jpg?1"); } // alias to 6756
.collage__tab_celebrity { background-image: url("/assets/img/tabs/celebrity.jpg?1"); }
.collage__tab_celebrity2 { background-image: url("https://assets.photo-cdn.net/previews/256x256/1/6756.jpg"); }
.collage__tab_gloss { background-image: url("https://assets.photo-cdn.net/previews/256x256/1/6611.jpg"); }
.collage__tab_t6032p7027 { background-image: url("https://assets.photo-cdn.net/previews/256x256/1/6032_7027.jpg"); }
.collage__tab_t7034 { background-image: url("https://assets.photo-cdn.net/previews/256x256/1/7034.jpg"); }
.collage__tab_simpsons3 { background-image: url("https://assets.photo-cdn.net/previews/256x256/1/5938.jpg"); }
.collage__tab_halloween { background-image: url("https://assets.photo-cdn.net/previews/256x256/1/7083_7425.jpg"); }
.collage__tab_midjorney { background-image: url("https://assets.photo-cdn.net/previews/256x256/1/7516_7520.jpg"); }
