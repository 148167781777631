.modal-container {
    background-color: rgba(77,94,246,.86);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
}

.modal {
    border-radius: 40px;
    box-shadow: 0 8px 60px 0 rgba(43,43,97, .6);
    background-color: $color-white;
    box-sizing: border-box;
    max-width: 800px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 64px 24px;
    h3 {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 48px;
        line-height: 1.5;
        text-align: center;
        max-width: 560px;
        margin: 0 auto 24px;
    }
    p {
        font-size: 24px;
        line-height: 1.67;
        text-align: center;
        max-width: 560px;
        margin: 0 auto 40px;
    }
    .btns-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .btns-container_share {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 560px;
    }
    button, .btns-container a {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 24px;
        line-height: 1;
        letter-spacing: 0.96px;
        text-transform: uppercase;
        border-radius: 20px;
    }
    .btns-container a {
        line-height: 80px;
    }
    .btn-paint {
        height: 80px;
        padding: 0 33px;
        background-color: $color-secondary;
        color: $color-white;
    }
    .btn-empty {
        padding: 0 33px;
        background-color: transparent;
        color: $color-grey-dark;
    }
    .btn-cancel {
        color: $color-grey-dark;
        text-transform: uppercase;
        max-width: initial;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px auto 0;
        svg {
            width: 24px;
            height: 24px;
            fill: $color-grey-dark;
            margin-right: 16px;
        }
        span {
            margin-top: 3px;
        }
    }
    .btn-download {
        display: flex;
        align-items: center;;
        text-align: left;
        color: $color-primary;
        text-transform: uppercase;
        background-color: transparent;
        margin: 44px auto 0;
        svg {
            width: 32px;
            height: 32px;
            margin-right: 16px;
        }
    }
    .btn-share {
        width: 80px;
        height: 80px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        margin-right: 6px;
        svg {
            display: block;
            flex-shrink: 0;
            height: 40px;
            margin: 0 auto;
        }
    }
    .btn-share-insta {
        background-image: linear-gradient(45deg, #ffdd55 7%, #ff543e 50%, #c837ab 93%); 
        svg {
            width: 40px;
        }
    }
    .btn-share-snap {
        background-color: #ffdd0d;
        svg {
            width: 42px;
        }
    }
    .btn-share-fb {
        background-color: #1976d2;
        svg {
            width: 25px;
        }
    }
    .modal-text-processing {
        color: $color-grey-dark;
    }
    .icon-cat1 {
        position: absolute;
        top: -115px;
        right: 60px;
        height: 240px;
        z-index: -5;
    }
    .icon-cat2 {
        position: absolute;
        right: -110px;
        bottom: -60px;
        width: 220px;
        z-index: -5;
    }
    .icon-boom1 {
        position: absolute;
        left: -100px;
        top: -130px;
        width: 420px;
        z-index: -5;
    }
    .icon-boom2 {
        position: absolute;
        right: -55px;
        bottom: -45px;
        width: 420px;
        z-index: -5;
    }
    .check-foto-container {
        display: flex;
        justify-content: space-between;
        max-width: 560px;
        margin: 0 auto 40px;
    }
    .check-foto {
        width: 48%;
        border-radius: 16px;
        overflow: hidden;
        margin: 0;
        position: relative;
        border: solid 4px transparent;
        box-sizing: border-box;
        &.active {
            border-color: #7aafff;
        }
        &.not-active {
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 20;
                background-color: rgba(232, 136, 21, 0.2);
            }
        }
        img {
            height: 100%;
            object-fit: cover;
            display: block;
            width: 100%;
            position: absolute;
            top: 0; 
            left: 0; 
            bottom: 0; 
            right: 0;
            z-index: 2;
        }
        &:before {
            content: "";
            display: block;
            width: 100%;
            padding-top: 100%;
        }
    }
}


.modal-remove-logo,
.modal-container-rate,
.modal-container-clones {
    .btn-empty {
        margin-top: 40px;
    }
}

.modal-get-apps {
    .btns-container {
        display: block;
    }

    .btns-market-small-container {
        margin-bottom: 32px;
    }
}

.modal-container-download {
    background-color: transparent;
    .modal {
        padding: 20px 0 0;
        border-radius: 0;
        width: 100%;
        height: 100%;
        box-shadow: none;
        position: absolute;
        max-width: none;
        h3 {
            max-width: 770px;
            padding: 0 10px;
            margin-bottom: 0;
        }
        p {
            max-width: 770px;
            padding: 0 10px;
            margin-bottom: 20px;
        }
        .btn-paint {
            padding: 28px 139px 28px 140px;
            border-radius: 20px;
            background-color: #6674f4;
            position: absolute;
            bottom: 40px;
            z-index: 100;
        }
    }
    .btn-cancel {
        z-index: 400;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 32px;
        height: 32px;
        padding: 6px;
        display: flex;
        background: none;
        margin: 0;
        svg {
            width: 100%;
            height: 100%;
            margin: 0;
        }
    }
}

.modal-download-bg {
    position: relative;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    display: flex;
    background-color: rgba(45, 49, 52, 1);
    // filter: blur(2px);
    overflow: hidden;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(45, 49, 52, 0.1);
    }

    .bg-column {
        // width: 20%;
        flex: 1 1;
        position: relative;
        // overflow: hidden;
        animation: var(--animation, slide) linear;
        animation-duration: 300s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        &:nth-of-type(odd) {
            animation-duration: 240s;
            picture {
                transform: translateY(-50%);
            }
        }

        @media all and (max-width: 1500px) {
            // width: 25%;

            &:nth-child(6) {
                display: none;
            }
        }

        @media all and (max-width: 800px) {
            // width: 34%;

            &:nth-child(5) {
                display: none;
            }

            animation-duration: 20s;

            &:nth-of-type(odd) {
                animation-duration: 15s;
            }
        }

        @media all and (max-width: 540px) {
            // width: 50%;

            &:nth-child(4) {
                display: none;
            }

            animation-duration: 20s;

            &:nth-of-type(odd) {
                animation-duration: 15s;
            }
        }
    }

    picture {
        width: 100%;
        padding-top: 100%;
        display: block;
        position: relative;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.-show-popup {
    overflow: hidden;
}

@keyframes slide {
    to {
        -webkit-transform: translateY(var(--direction, -47%));
        transform: translateY(var(--direction, -47%));
    }
}

// @keyframes column1 {
//     0% {
//         top: 0;
//     }

//     100% {
//         top: 100%;
//     }
// }

// @keyframes column2 {
//     0% {
//         bottom: 100%;
//     }

//     100% {
//         bottom: -200%;
//     }
// }

.modal-container-snapchat {
    .btns-container {
        align-items: flex-start;
        max-width: 560px;
        margin: 0 auto;
    }
    .btn-download {
        color: $color-grey-dark;
        padding: 0;
        margin: 0;
        svg {
            fill: $color-grey-dark;
        }
    }
}

.modal-container-snapchat {
    background-color: $color-yellow;
}

@mixin animation($name, $duration, $delay, $count) {
    -webkit-animation: $name $duration $delay $count;
    -moz-animation: $name $duration $delay $count;
    -o-animation: $name $duration $delay $count;
    -ms-animation: $name $duration $delay $count;
    animation: $name $duration $delay $count;
  }

.animation-hearts-block {
    position: absolute;
    bottom: 100px;
    z-index: 100;
    width: 100%;
    max-width: 340px;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    z-index: 90;

    .heart1,
    .heart4,
    .heart7,
    .heart21,
    .heart24,
    .heart27 {
        width: 20px;
        height: 20px;
        position: absolute;
        @include animation(moveup1, 4.5s, .2s, infinite);
    }

    .heart2,
    .heart9,
    .heart22,
    .heart29 {
        width: 12px;
        height: 12px;
        position: absolute;
        @include animation(moveup1, 5s, .3s, infinite);
    }

    .heart3,
    .heart5,
    .heart10,
    .heart23,
    .heart25,
    .heart30 {
        width: 16px;
        height: 16px;
        position: absolute;
        @include animation(moveup1, 3.5s, .4s, infinite);
    }

    .heart6,
    .heart8,
    .heart26,
    .heart28 {
        width: 24px;
        height: 24px;
        position: absolute;
        @include animation(moveup1, 5s, 2s, infinite);
    }

    .heart11,
    .heart14,
    .heart17,
    .heart31,
    .heart34,
    .heart37 {
        width: 20px;
        height: 20px;
        position: absolute;
        @include animation(moveup1, 5.5s, .2s, infinite);
    }

    .heart12,
    .heart19,
    .heart32,
    .heart39 {
        width: 12px;
        height: 12px;
        position: absolute;
        @include animation(moveup1, 3.5s, .5s, infinite);
    }

    .heart13,
    .heart15,
    .heart20,
    .heart33,
    .heart35,
    .heart40 {
        width: 16px;
        height: 16px;
        position: absolute;
        @include animation(moveup1, 4s, .7s, infinite);
    }

    .heart16,
    .heart18 {
        width: 24px;
        height: 24px;
        position: absolute;
        @include animation(moveup1, 5s, .2s, infinite);
    }

    .heart4,
    .heart14 {
        animation-delay: 0;
    }

    .heart5,
    .heart15 {
        animation-delay: .7s;
    }

    .heart7,
    .heart17 {
        animation-delay: 0.6s;
    }

    .heart8,
    .heart18 {
        animation-delay: .4s;
    }

    .heart9,
    .heart19 {
        animation-delay: 06s;
    }

    .heart30,
    .heart40,
    .heart21 {
        animation-delay: .8s;
    }

    .heart24,
    .heart34,
    .heart22 {
        animation-delay: .4s;
    }

    .heart25,
    .heart35,
    .heart26  {
        animation-delay: 1.1s;
    }

    .heart27,
    .heart37,
    .heart23 {
        animation-delay: 1s;
    }

    .heart28,
    .heart38,
    .heart32 {
        animation-delay: .8s;
    }

    .heart39,
    .heart49,
    .heart33 {
        animation-delay: 1.4s;
    }

    .heart30,
    .heart40,
    .heart31 {
        animation-delay: .8s;
    }

    .heart1 {
        left: 0;
    }
    .heart2 {
        left: 5%;
    }
    .heart3 {
        left: 15%;
    }
    .heart4 {
        left: 20%;
    }
    .heart5 {
        left: 25%;
    }
    .heart6 {
        left: 30%;
    }
    .heart7 {
        left: 35%;
    }
    .heart8 {
        left: 40%;
    }
    .heart9 {
        left: 45%;
    }
    .heart10 {
        left: 50%;
    }
    .heart11 {
        left: 55%;
    }
    .heart12 {
        left: 60%;
    }
    .heart13 {
        left: 65%;
    }
    .heart14 {
        left: 70%;
    }
    .heart15 {
        left: 75%;
    }
    .heart16 {
        left: 80%;
    }
    .heart17 {
        left: 85%;
    }
    .heart18 {
        left: 90%;
    }
    .heart19 {
        left: 95%;
    }
    .heart20 {
        left: 100%;
    }
    .heart21 {
        left: 3%;
    }
    .heart22 {
        left: 8%;
    }
    .heart23 {
        left: 23%;
    }
    .heart24 {
        left: 28%;
    }
    .heart25 {
        left: 33%;
    }
    .heart26 {
        left: 38%;
    }
    .heart27 {
        left: 43%;
    }
    .heart28 {
        left: 48%;
    }
    .heart29 {
        left: 53%;
    }
    .heart30 {
        left: 58%;
    }
    .heart31 {
        left: 63%;
    }
    .heart32 {
        left: 68%;
    }
    .heart33 {
        left: 73%;
    }
    .heart34 {
        left: 78%;
    }
    .heart35 {
        left: 83%;
    }
    .heart36 {
        left: 88%;
    }
    .heart37 {
        left: 93%;
    }
    .heart38 {
        left: 98%;
    }
    .heart39 {
        left: 103%;
    }
    .heart40 {
        left: 100%;
    }
}

@keyframes moveup1 {
    0% {
        bottom: 0;
        opacity: 1;
    }
    70% {
        opacity: .6;
    }
    100% {
        bottom: 400px;
        opacity: 0;
    }
}

@media all and (max-width: 900px) {
    .modal {
        border-radius: 24px;
        padding: 32px 24px;
        h3 {
            font-size: 28px;
            line-height: 1.43;
        }
        p {
            font-size: 16px;
            line-height: 1.5;
        }
        button {
            font-size: 16px;
            border-radius: 16px;
            padding: 0 20px;
        }
        .btns-container a {
            font-size: 16px;
            border-radius: 16px;
            padding: 0 20px;
            line-height: 56px;
        }
        .btn-paint {           
            height: 56px;
            padding: 0 16px;
        }
        .btn-cancel {
            margin-top: 24px;
            svg {
                width: 14px;
                height: 14px;
                margin-right: 8px;
            }
            span {
                margin-top: 2px;
            }
        }
        .btn-download {
            margin-top: 28px;
            svg {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }
        .btn-share {
            width: 56px;
            height: 56px;
            svg {
                height: 24px;
            }
        }
        
        .btn-share-insta {
            svg {
                width: 24px;
            }
        }
        .btn-share-snap {
            svg {
                width: 25px;
            }
        }
        .btn-share-fb {
            svg {
                width: 13px;
            }
        }
        .icon-cat1 {
            top: -100px;
            right: 60px;
            height: 180px
        }
        .icon-cat2 {
            right: -84px;
            bottom: -60px;
            width: 170px;
        }
        .icon-boom1 {
            left: -60px;
            top: -60px;
            width: 190px;
        }
        .icon-boom2 {
            right: -35px;
            bottom: -25px;
            width: 230px;
        }
    }
    .modal-remove-logo,
    .modal-container-rate,
    .modal-container-clones { 
        .btn-empty {
            margin-top: 24px;
        }
    }
    .modal-container-snapchat {
        .btns-container {
            align-items: flex-start;
            .btn-download {
                margin: 0;
            }
        }
    }
    .modal-container-download {
        .modal {
            display: flex;
            flex-direction: column;
            padding: 15px 0 0;
            border-radius: 0;
            h3 {
                font-size: 24px;
            }
            p {
                font-size: 16px;
                margin-bottom: 16px;
            }
            .btns-container {
                flex-shrink: 0;
                order: 5;
                padding: 10px 0;
            }
            .btn-paint {
                position: static;
                padding: 0 20px;
                background-color: #4d5ef6;
            }
        }
        
        .btn-cancel {
            top: 4px;
            right: 4px;
            width: 28px;
            height: 28px;
            margin: 0;
            svg {
                width: 100%;
                height: 100%;
                margin: 0;
            }
        }
    }

    .modal-container-rate {
        .modal {
            p {
                margin-bottom: 16px;
            }
        }
    }

    .animation-hearts-block {
        position: absolute;
        bottom: 96px;
    }

    @at-root .app-webview--ios.ios12 .modal-container-download .modal .btns-container {
        padding: 28px 0;
    }
}



// .modal { 
//     padding: 40px 24px;
//     border-radius: 24px;
//     box-shadow: 0 8px 60px 0 rgba(43,43,97, .6);
//     background-color: #ffffff;
//     box-sizing: border-box;
//     max-width: 800px;
//     width: 90%;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%,-50%);
//     &-text-processing {
//         color: $color-grey-dark;
//         margin-top: 16px;
//         margin-bottom: 16px;
//     }
//     &-remove-logo {
//         p {
//             font-size: 16px;
//             line-height: 1.5;
//             text-align: center;
//             margin-bottom: 40px;
//         }
//         .btn-upload-foto {
//             width: 200px;
//             margin-bottom: 10px;
//         }
//     }
//     &-container {
//         background-color: rgba(77, 94, 246, 0.86);
//         backdrop-filter: blur(10px);
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         z-index: 100;
//         &.snapchat {
//             background-color: $color-yellow;
//             h3 {
//         font-family: $font-primary;
//          font-weight: 600;
//                 font-size: 28px;
//                 line-height: 1.43;
//                 text-align: center;
//                 margin-bottom: 24px;
//             }
//             p {
//                 margin-bottom: 36px;
//             }
//             .btn-share-snapchat {
//                 width: 200px;
//                 height: 48px;
//                 border-radius: 72px;
//                 background-color: #ffdd0d;
//                 font-weight: bold;
//                 text-transform: uppercase;
//                 display: block;
//                 margin: 0 auto;
//             }
//             .btn-back {
//         font-family: $font-primary;
//          font-weight: 600;
//                 font-size: 16px;
//                 line-height: 1;
//                 text-align: left;
//                 color: $color-grey-dark;
//                 display: block;
//                 height: auto;
//                 width: auto;
//                 position: static;
//                 box-shadow: none;
//                 background-color: transparent;
//                 padding: 0;
//                 margin: 0;
//                 svg {
//                     width: 16px;
//                     height: 16px;
//                     fill: $color-grey-dark;
//                     margin-right: 8px;
//                 }
//                 span {
//                     margin-top: 2px;
//                 }
//             }
//             .check-foto-container {
//                 display: flex;
//                 justify-content: space-between;
//                 margin-bottom: 40px;
//             }
//             .check-foto {
//                 width: 48%;
//                 border-radius: 16px;
//                 overflow: hidden;
//                 margin: 0;
//                 position: relative;
//                 background-color: grey;
//                 img {
//                     height: 100%;
//                     object-fit: cover;
//                     display: block;
//                     width: 100%;
//                     position: absolute;
//                     top: 0; 
//                     left: 0; 
//                     bottom: 0; 
//                     right: 0;
//                     z-index: 2;
//                 }
//                 &:before {
//                     content: "";
//                     display: block;
//                     width: 100%;
//                     padding-top: 100%;
//                 }
//             }
//         }
//     }
    
//     &-download {
//         .modal {
//             padding-top: 90px;
//         }
//     }
//     h3 {
//         font-family: $font-primary;
//          font-weight: 600;
//         font-size: 28px;
//         line-height: 1.43;
//         text-align: center;
//         margin-bottom: 24px;
//     }
//     p {
//         font-size: 16px;
//         line-height: 1.5;
//         text-align: center;
//     }
//     .btn-transparent {
//         font-family: $font-primary;
//          font-weight: 600;
//         font-size: 14px;
//         color: $color-grey-dark;
//         text-transform: uppercase;
//         width: 200px;
//         height: 48px;
//         border-radius: 16px;
//         background-color: transparent;
//         display: block;
//         margin: 0 auto;
//     }
//     .btn-upload-foto {
//         &.small {
//             margin: 0;
//             max-width: 96px;
//         }
//         &.download {
//         font-family: $font-primary;
//          font-weight: 600;
//             font-size: 16px;
//             line-height: 1;
//             max-width: 100%;
//             height: auto;
//             display: flex;
//             align-items: center;;
//             letter-spacing: 0.64px;
//             text-align: left;
//             color: #2d3134;
//             text-transform: uppercase;
//             background-color: transparent;
//             margin: 24px auto 0;
//             svg {
//                 width: 24px;
//                 height: 24px;
//                 fill: #000;
//                 margin-right: 8px;
//             }
//         }
//     }
//     .btns-container_share {
//         display: flex;
//         align-items: center;
//         justify-content: space-around;
//         width: 100%;
//     }
//     .btn-share {
//         width: 47px;
//         height: 47px;
//         background-color: transparent;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-shrink: 0;
//         border-radius: 50%;
//     }
    
//     .btn-share-insta {
//         background-image: linear-gradient(45deg, #ffdd55 7%, #ff543e 50%, #c837ab 93%); 
//         svg {
//             display: block;
//             height: 24px;
//             width: 24px;
//             margin: 0 auto;
//         }
//     }
//     .btn-share-snap {
//         background-color: #ffdd0d;
//         svg {
//             display: block;
//             height: 24px;
//             width: 25px;
//             margin: 0 auto;
//         }
//     }
//     .btn-share-fb {
//         background-color: #1976d2;
//         svg {
//             display: block;
//             height: 24px;
//             width: 13px;
//             margin: 0 auto;
//         }
//     }
//     .btn-cancel {
//         font-family: $font-primary;
//          font-weight: 600;
//         line-height: 1;
//         letter-spacing: 0.64px;
//         text-align: center;
//         color: $color-grey-dark;
//         text-transform: uppercase;
//         max-width: initial;
//         background-color: transparent;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         margin: 0 auto;
//         svg {
//             width: 14px;
//             height: 14px;
//             fill: $color-grey-dark;
//             margin-right: 8px;
//         }
//         span {
//             margin-top: 2px;
//         }
//     }
// }

// .icon-cat1 {
//     position: absolute;
//     top: -115px;
//     right: 60px;
//     height: 200px;
// }



