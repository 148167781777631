.main-page {
    height: 100vh;
}

.main-page .container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.main-title {
    font-family: 'Chewy', "Helvetica", sans-serif;
    font-size: 36px;
    line-height: 1.3;
    text-align: center;
    color: #3fb3f9;
    width: 100%;
    display: block;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-bottom: 12px;
}

p {
    text-align: center;
    line-height: 1.38;
    margin-bottom: 8px;
}

.wr-slider {
    // flex-grow: 1;
    width: 100%;
    margin: 3vh auto 6.5vh;
}

.wr-slider img {
    width: 100%;
}

.wr-slider .slick-list,
.wr-slider .slick-slider {
    position: relative;
}

.wr-slider .slick-dots li {
    width: 5px;
    height: 5px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    vertical-align: middle;
    margin: 0 4px;
    transition: 500ms;
}

.wr-slider .slick-dots button {
    width: 100%;
    height: 100%;
}

.wr-slider .slick-dots button {
    background-color: #ffdd0d;
    border-radius: 3px;
}

.wr-slider .slick-dots .slick-active button {
    background-color: #ffdd0d;
}

.wr-slider .slick-dots .slick-active {
    width: 30px;
}

.wr-slider .slick-dots {
    bottom: -30px;
}

.slide {
    display: flex;
    border-radius: 20px;
    border: solid 5px transparent;
    width: 270px;
    height: 270px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;

    img {
        width: 100%;
        height: 100%;
    }
}

.slick-active {
    .slide {
        border-color: #ffdd0d;
    }
}

// .slide-container {
//     padding: 8px 0;
// }

// .slick-slide {
//     margin: 0 15px;
// }

// .slick-list {
//     margin: 0 -15px;
// }

.slide-item {
    margin: 0 7px 14px;
}

.slide-item h2 {
    font-size: 16px;
    font-weight: bold;
    font-style: oblique;
    line-height: 1;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
}

.slide-image-container {
    max-width: 140px;
    border-radius: 16px;
    overflow: hidden;
}

.slide-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-item-active {
    position: relative;
}

.slide-item-active .slide-image-container {
    box-shadow: 0 0 120px 0 #7ecd21;
    border: 2px solid #7ecd21;
    box-sizing: border-box;
    max-width: 200px;
}

.btn-upload-foto {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    max-width: 310px;
    width: 90%;
    height: 48px;
    border-radius: 100px;
    background-color: #3fb3f9;
    display: block;
    flex-shrink: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin: 0 auto;

    &:active {
        opacity: .6;
    }
}

.upload-foto-text {
    display: block;    
    color: #222;
    text-align: center;
    line-height: 1.38;
    border-top: 1px dashed #3fb3f9;
    position: relative;
    left: -30px;
    width: calc(100% + 60px);
    box-sizing: border-box;
    padding: 15px 20px 11px;
    margin-top: 20px;
    margin-bottom: 0;
    span {
        font-weight: 600;
        color: #3fb3f9;
        display: block;
    }
}

.btn-get-app {
    animation: borderPulse 1s infinite ease-out;
}

@keyframes borderPulse {
    0% {
      box-shadow:  0px 0px 0px 0px rgba(63,179,249,1);
    }
    100% {
      box-shadow:  0px 0px 0px 10px rgba(63,179,249,0);
    }
}

.btn-update {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    background-color: transparent;
    padding: 5px;
    position: absolute;
    // top: calc(50% + 5px);
    top: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    svg {
        // width: 23px;
        width: 30px;
        margin: 0 auto 5px;
    }
    span {
        font-size: 10px;
        color: #000;
    }
}

.btn-refresh-foto {
    left: calc(50% + 140px);
}

.btn-another-foto {
    font-size: 15px;
    font-weight: bold;
    color: #f2bc19;
    display: flex;
    align-items: center;
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    &:hover {
        opacity: .6;
    }
}

.create-page h2 {
    font-weight: bold;
    color: #333;
    line-height: 1.38;
    text-align: center;
    margin-bottom: 20px;
}

.create-page .create-steps {
    line-height: 1.38;
    text-align: center;
    color: #999;
    margin-bottom: 8px;
}

.creatives {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;
}

.creative {
    width: 48%;
    position: relative;
    margin: 0 0 15px;
}

.creative .num-check-foto {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #05b0ff;
    display: none;
    align-items: center;
    justify-content: center;
}

.creative .holder {
    position: relative;
    margin-bottom: 8px;
    padding-top: 100%;
    background-color: #eeeeee;
}

.creative.active .num-check-foto {
    display: flex;
}

.creative img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.creative.active img {
    // box-shadow: 0 0 40px 0 #c77dff;
    border: solid 3px #05b0ff;
    box-sizing: border-box;
}

.creative p {
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: #333;
    text-transform: capitalize;
}

.create-page .btn-upload-foto {
    position: fixed;
    bottom: 15px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.create-page .btn-upload-foto.disabled {
    background-color: #3b3c3e;
}

.collage-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
    background-color: #fff;
    padding-bottom: 20px;
    .btn-upload-foto {
        max-width: 200px;
    }
    .btn-back {
        margin-bottom: 5.4vh;
    }
}

.collage-page .creative-holder {
    position: relative;
    max-width: 450px;
    width: 100%;
    margin: 0 auto 30px;

    .creative-holder-placeholder {
        padding-top: 100%;
        & ~ .imageview-host {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    .wait-video, img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 20;
    }

    .imageview-host, video {
        position: relative;
        border-radius: 24px;
        overflow: hidden;
        z-index: 20;
    }

    .wait-video {
        z-index: 5;
        border-radius: 24px;
    }
}

.collage-page img,
.collage-page video {
    width: 100%;
    max-width: 450px;
    // max-height: 450px;
    height: 100%;
    display: block;
    margin: 0 auto;
}

.collage-page img {
    object-fit: cover;
}
.collage-page video {
    object-fit: contain;
}

.collage-page .container {
    display: flex;
    flex-direction: column;
}

.collage-page-content {
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 16px;
    &.container {
        padding-top: 0;
    }
}
.collage-page-text {
    margin-bottom: 27px;
}

/*loader*/
.loader {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: relative;
}

.collage-page-text {
    margin-bottom: 27px;
}

.loader p {
    font-family: 'Chewy', "Helvetica", sans-serif;
    font-size: 24px;
    line-height: 1.25;
    text-align: center;
    color: #3fb3f9;
    position: absolute;
    top: calc(40% + 220px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
}

.spinner-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
    height: 140px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
}

.avatar-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
    height: 140px;
    border: solid 5px #3fb3f9;
    box-sizing: border-box;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    animation-name: fadein;
    animation-duration: 320ms;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

.spinner-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.triple-spinner {
    display: block;
    position: relative;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    border: 8px solid #f242b3;
    border-left-color: transparent;
    animation: spin 3s linear infinite;
}

.triple-spinner::before,
.triple-spinner::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 8px solid transparent;
}

.triple-spinner::before {
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-color: #3fb3f9;
    border-bottom-color: transparent;
    animation: spin-reverse 1.5s linear infinite;
}

.triple-spinner::after {
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    border-color: #ffdd0d;
    border-left-color: transparent;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes spin-reverse {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

.btns-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &_share {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
    }
}

.btn-share {
    width: 47px;
    height: 47px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
}

.btn-share-insta {
    background-image: linear-gradient(45deg, #ffdd55 7%, #ff543e 50%, #c837ab 93%); 
    svg {
        display: block;
        height: 24px;
        width: 24px;
        margin: 0 auto;
    }
}
.btn-share-snap {
    background-color: #ffdd0d;
    svg {
        display: block;
        height: 24px;
        width: 25px;
        margin: 0 auto;
    }
}
.btn-share-fb {
    background-color: #1976d2;
    svg {
        display: block;
        height: 24px;
        width: 13px;
        margin: 0 auto;
    }
}

/*modal*/
.-show-popup {
    overflow: hidden;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffdd0d;
    z-index: 60;
    .bg-result {
        position: absolute;
        left: 0;
        width: 100%;
    }
    .bg-bottom {
        bottom: 0;
    }
    .bg-top {
        top: 0;
    }
    .cloud-top {
        position: absolute;
        width: 75px;
        top: 5%;
        left: 20%;
    }
    .cloud-right {
        position: absolute;
        width: 80px;
        bottom: 5%;
        right: 5%;
    }
    .cloud-left {
        position: absolute;
        width: 52px;
        bottom: 5%;
        left: 5%;
    }
}

.hidden-image {
    position: absolute;
    top: -9999px;
    left: -9999px;
    opacity: 0;
}

.modal {
    max-width: 380px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    border-radius: 16px;
    border: solid 10px #000000;
    background-color: #fff;
    z-index: 20;
    padding: 56px 30px;
    .btn-upload-foto {
        color: #fff;
        max-width: 100px;
        width: 100%;
        background-color: #3fb3f9;
        margin: 0;

        &.download {
            font-weight: 400;
            text-transform: inherit;
            text-decoration: underline;
            background-color: transparent;
            color: #80878b;
            margin-top: 10px;
            max-width: 200px;
            &.btn-cancel {
                max-width: 274px;
                line-height: 1.2;
                margin: 10px auto 0;
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: #000;
        margin-bottom: 24px;
    }
    .text-bold {
        font-weight: bold;
        margin-bottom: 10px;
    }
    .modal-bg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 160%;
        height: 160%;
        z-index: -5;
        transform: translate(-50%, -50%);
    }
    h3 {
        font-family: 'Chewy', "Helvetica", sans-serif;
        font-size: 36px;
        line-height: 1;
        text-align: center;
        color: #3fb3f9;
        margin-bottom: 24px;
    }
    .loader-video {
        justify-content: center;
        position: static;
        transform: none;
    }
    .btns-container {
        flex-direction: column;
    }
    &.rate {
        padding: 56px 35px;
        h3 {
            color: #e434a5;
            margin-bottom: 10px;
        }
        .btn-upload-foto {
            background-color: #e434a5;
            max-width: 200px;
            margin: 0 auto;
            &.download {
                font-weight: 400;
                text-transform: inherit;
                text-decoration: underline;
                background-color: transparent;
                color: #80878b;
                margin-top: 10px;
                max-width: 200px;
            }
        }
    }
    &.clones {
        padding: 56px 35px;
        h3 {
            color: #e43436;
        }
        p {
            margin-bottom: 30px;
        }
        .btn-upload-foto {
            background-color: #e43436;
            max-width: 200px;
            margin: 0 auto;
            &.download {
                font-weight: 400;
                text-transform: inherit;
                text-decoration: underline;
                background-color: transparent;
                color: #80878b;
                margin-top: 10px;
                max-width: 200px;
            }
        }
    }

    &.snapchat {
        padding: 28px 16px;
        h3 {
            font-family: "Chewy";
            font-size: 38px;
            line-height: 1.1;
            text-align: center;
            color: #ffce0a;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 17px;
        }
        .btn-share-snapchat {
            width: 200px;
            height: 48px;
            border-radius: 72px;
            background-color: #ffdd0d;
            font-weight: bold;
            text-transform: uppercase;
            display: block;
            margin: 0 auto;
        }
        .btn-back {
            line-height: 1.5;
            text-align: center;
            color: #80878b;
            text-decoration: underline;
            display: block;
            font-weight: normal;
            width: 200px;
            height: 24px;
            background-color: transparent;
            padding: 0;
            margin: 12px auto 0;
        }
        .check-foto-container {
            display: flex;
            justify-content: center;
            margin-bottom: 25px;
        }
        .check-foto {
            width: 45%;
            border-radius: 12px;
            overflow: hidden;
            margin: 0 5px;
            position: relative;
            background-color: grey;
            img {
                height: 100%;
                object-fit: cover;
                display: block;
                width: 100%;
                position: absolute;
                top: 0; 
                left: 0; 
                bottom: 0; 
                right: 0;
                z-index: 2;
            }
            &:before {
                content: "";
                display: block;
                width: 100%;
                padding-top: 100%;
            }
        }
    }

    &.modal-remove-logo {
        .btns-container_share .btn-upload-foto {
            max-width: 260px;
        }
    }
}

/*error-page*/
.error-page .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 94vh;
}

.error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.38;
}

.error-page svg {
    max-width: 264px;
    margin-bottom: 5.5vh;
}

.error-page h3 {
    font-weight: bold;
    margin-bottom: 16px;
}

.btn-back {
    font-size: 15px;
    font-weight: bold;
    color: #000;
    display: flex;
    align-items: center;
    background-color: transparent;
    position: relative;
    top: -5px;
    left: -5px;
    padding: 5px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-bottom: 9px;
}

.btn-back svg {
    fill: #000;
    width: 11px;
    margin-right: 10px;
}

.collage-page h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.38;
    text-align: center;
    color: #fff;
    width: 100%;
    margin-bottom: 16px;
    text-transform: uppercase;
}

.share-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    position: relative;
    // left: -30px;
    // width: calc(100% + 60px);
    padding-top: 12px;
    padding-bottom: 12px;
    // border-top: 1px solid #d2d2d2;
    // border-bottom: 1px solid #d2d2d2;
    // margin-top: 10px;
}

.share-container button {
    width: 40px;
    height: 40px;
    border: none;
    background: no-repeat;
    flex-shrink: 0;
    margin-left: 24px;

    &:last-child {
        width: 1px;
    }
}

.share-container svg {
    width: 100%;
}

.steps-progress {
    position: fixed;
    height: 4px;
    width: 100%;
    background-color: #e7e7e7;
    top: 0;
    left: 0;
    z-index: 50;

    span {
        transition: 500ms;
        background-color: #feca28;
        display: block;
        height: 100%;
    }
}

.tabs-container {
    display: flex;
    //padding-left: 27px;
    position: relative;
    left: -30px;
    width: calc(100% + 60px);
    overflow: auto;
    box-sizing: border-box;
    z-index: 50;
    padding-bottom: 8px;
    padding-left: 30px;
    margin: 0 auto 12px;

    // button {
    //     font-family: 'Helvetica', sans-serif;
    //     line-height: 1.38;
    //     text-align: center;
    //     color: #000;
    //     flex-grow: 1;
    //     flex-shrink: 1;
    //     border-bottom: 2px solid transparent;
    //     background: none;
    //     box-sizing: border-box;
    //     padding-bottom: 6px;
    // }

    // .active-tab {
    //     font-weight: bold;
    //     color: #3fb3f9;
    //     border-color: #3fb3f9;
    // }

    button {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 8px;
        border: solid 3px #fff;
        margin: 0 6px 0 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        box-sizing: content-box;
        flex-shrink: 0;

        img {
            border-radius: 5px;
        }

        .new-label {
            position: absolute;
            top: 0;
            right: 0;
            height: 14px;
            line-height: 14px;
            padding: 3px 6px 0;
            border-radius: 0 4px 0 4px;
            background-color: #ffdd0d;
            text-transform: uppercase;
            font-family: Helvetica, sans-serif;
            font-size: 9px;
            font-weight: bold;
            color: #000;
        }
    }
    .active-tab {
        border-color: #3fb3f9;
        box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.25);
    }
}

.collage__tab_labs {
    background-image: url("/assets/img/tabs/tab-icon-labs.jpg");
}
.collage__tab_classic {
    background-image: url("/assets/img/tabs/tab-icon-classic.png");
}

.tabs-container .collage__tab_love {
    background-image: url("/assets/img/tabs/tab-icon-love.png");
    background-color: #f0679f;
    background-size: 36px;
    background-position: center;
}

.tab-love {
    padding: 0;
    .bg-image-container {
        margin-top: -70px;
        img {
            width: 110%;
        }
    }
    &-content {
        margin-top: -60px;
    }
}

.choose-page {
    h2 {
        font-family: "Helvetica",sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: #000;
        text-transform: uppercase;
        line-height: 1.13;
        text-align: center;
        margin-bottom: 5px;
    }
    p {
        font-family: "Helvetica",sans-serif;
        line-height: 1.38;
        text-align: center;
        width: 100%;
    }
}
.choose-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 440px;
    margin: 0 auto;
}
.choose-item-container {
    width: 33%;
    margin-bottom: 1px;
}
.choose-item {
    position: relative;
    padding-top: 100%;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}

.wait-video {
    overflow: hidden;
    max-width: 450px;
    margin: 0 auto;

    img {
        filter: blur(7px);
    }
}

.loader-video {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
}

.loader-video .item-loader {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    animation: item-loader .9s linear infinite;
    margin: 0 6px;
}

.loader-video .first-item-loader {
    background-color: #1db8ff;
}

.loader-video .second-item-loader {
    background-color: #ffd300;
    animation: item-loader .9s linear infinite;
    animation-delay: .3s;
}

.loader-video .third-item-loader {
    background-color: #fc4700;
    animation: item-loader .9s linear infinite;
    animation-delay: .6s;
}


@keyframes item-loader {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.8, 1.8);
    }
}

.btns-market-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.btn-store {
    color: #000;
    display: flex;
    align-items: center;
    max-width: 218px;
    width: 100%;
    height: 66px;
    border-radius: 16px;
    background-color: #ffdd0d;
    box-sizing: border-box;
    padding-left: 21px;
    margin: 0 4px;
    &-title {
        font-size: 14px;
        line-height: 1;
        display: block;
        margin-bottom: 4px;
    }
    &-name {
        font-size: 18px;
        font-weight: bold;
        line-height: 1;
    }
    svg {
        fill: #000;
        width: 21px;
        margin-right: 21px;
    }
    &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.btn-clone-container {
    position: relative;
    align-self: center;
    margin-top: 16px;
    margin-bottom: 30px;
    p {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #e43436;
        position: absolute;
        top: 4px;
        left: 63px;
        text-decoration: underline;
        text-transform: uppercase;
        margin: 0;
    }
}
.btn-clone-icon {
    width: 290px;
}
.icon-cursor {
    width: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -16px;
}

&.app-locale {
    &--hi, &--ja, &--ko, &--ru, &--sc, &--th {
        .main-title {
            font-family: "Helvetica", sans-serif;
        }
        .loader p {
            font-family: "Helvetica", sans-serif;
        }
        .modal h3 {
            font-family: "Helvetica", sans-serif;
        }
    }
}

/*article-page*/
.article-page {
    .container {
        max-width: 1032px;
        padding: 27px 16px;
    }
    h1 {
        text-align: center;
        margin-bottom: 45px;
    }
    h3 {
        // font-family: 'Chewy';
        font-size: 22px;
        font-weight: bold;
        line-height: 1.33;
        color: #3fb3f9;
        margin-bottom: 33px;
    }
    p {
        font-size: 14px;
        line-height: 1.67;
        color: #000;
        text-align: left;
        margin-bottom: 14px;
    }
    // .text-weight {
    //     font-weight: bold;
    // }
    // .text-title {
    //     font-family: 'Chewy';
    //     font-size: 18px;
    //     letter-spacing: 1.33px;
    //     color: #3fb3f9;
    // }
    // .text-primary {
    //     color: #3fb3f9;
    // }
    // .text-italic {
    //     font-style: oblique;
    // }
    // .text-notice {
    //     font-family: 'Chewy';
    //     font-size: 22px;
    //     line-height: 1.25;
    //     letter-spacing: 1px;
    //     text-align: center;
    //     color: #c7c7c7;
    //     text-align: center;
    // }
    // .text-uppercase {
    //     text-transform: uppercase;
    // }
    .btns-market-container {
        margin-bottom: 30px;
    }
    // .btn-store {
    //     max-width: 320px;
    //     height: 88px;
    //     position: relative;
    //     padding-left: 32px;
    //     margin: 0;
    //     margin-right: 24px;
    //     svg {
    //         width: 26px;
    //         margin-right: 24px;
    //     }
    // }
    // .btn-store-title {
    //     font-size: 24px;
    // }
    // .btn-store-name {
    //     font-size: 24px;
    //     line-height: 1.33;
    // }
    .link-back {
        font-family: 'Chewy';
        font-size: 30px;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1px;
        text-align: center;
        color: #fff;           
        border-radius: 44px;
        background-color: #3fb3f9;
        display: inline-block;
        padding: 7px 17px;
    }
}

.decorate-image {
    width: 100%;
    border-radius: 24px;
}

.btn-contacts-form {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    color: #3fb3f9;
    text-decoration: underline;
    text-transform: uppercase;
    background-color: transparent;
    display: block;
    width: auto;
    margin: 12px auto 10px;
    padding: 4px;
}

.logo-container {
    a {
        margin-bottom: 30px;
    }
    img {
        filter: grayscale(1);
        max-width: 120px;
    }
}
.logo-container1,
.logo-container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 23%;
}
.logo-container1 {
    left: -220px;
}

.logo-container2 {
    right: -220px
}
.title-contacts-form {
    margin: 0;
    margin-top: 40px;
}

.app-webview--android,
.app-webview--ios {
    .btn-contacts-form, 
    .logo-container,
    .title-contacts-form {
        display: none;
    }
}

/*watermark*/
.watermark-container {
    height: 68px;
    max-width: 56%;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 14px;
    z-index: 30;
    .btn-remove-logo {
        height: 30px;
        width: 190px;
        position: absolute;
        right: -14px;
        bottom: 0;
        cursor: pointer;
        margin: 0;
    }
    .tooltip {
        position: absolute;
        bottom: 36px;
        right: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 8px;
        padding: 5px 10px;
        p {
            color: #fff;
            margin: 0;
        }
        &:after {
            content: none;
        }
    }
    .btn-logo-on {
        font-size: 8px;
        font-weight: 600;
        line-height: 1.17;
        letter-spacing: 0.48px;
        text-transform: uppercase;
        color: #3fb3f9;
        position: absolute;
        bottom: -21px;
        left: auto;
        right: -2px;
        background-color: transparent;
        padding: 20px 10px 5px 10px;
    }
    &-ja {
        display: block;
        right: auto;
        left: 14px;
        .tooltip {
            right: auto;
            left: 0;
        }
        .btn-logo-on {
            right: auto;
            left: -8px;
        }
    }
}

/*labs*/
.btn-experiments {
    background-color: #e434a5;
    padding-top: 2px;
    margin-top: 16px;
}
.labs {
    .main-title {
        color: #e434a5;
    }
    .main-subtitle {
        margin-bottom: 20px;
        span {
            font-weight: bold;
        }
    }
    .btn-upload-foto {
        background-color: #e434a5;
    }
    .btn-get-app {
        animation: borderPulsePink 1s infinite ease-out;
    }
    @keyframes borderPulsePink {
        0% {
          box-shadow:  0px 0px 0px 0px #e434a5;
        }
        100% {
          box-shadow:  0px 0px 0px 10px rgba(63,179,249,0);
        }
    }
    .wr-slider {
        position: relative;
        margin: 4.6vh auto 8.2vh;
        .slick-dots {
            button {
                background-color: #3fb3f9;
                border-radius: 10px;
            }
            .slick-active button {
                background-color: #3fb3f9;
            }
        }
    }
    .slick-active {
        .slide {
            border-color: #fff;
        }
    }
    .updated-at {
        margin-top: 20px;
    }
    .btn-to-toonme {
        font-weight: bold;
        color: #3eb3f9;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 310px;
        width: 90%;
        height: 48px;
        border-radius: 100px;
        border: solid 2px #3fb3f9;
        background-color: transparent;
        margin: 0 auto;
    }
}

.square {
    position: absolute;
}
.square-big {
    left: 50%;
    top: -20px;
    width: 350px;
    height: 350px;
    transform: rotate(15deg) translateX(-50%);
    opacity: 0.2;
    border-radius: 26px;
    filter: blur(15px);
    background-color: #3fb3f9;
    z-index: -10;
}
.square-med {
    right: 6%;
    top: -120px;
    width: 140px;
    height: 140px;
    transform: rotate(-10deg);
    opacity: 0.08;
    border-radius: 12px;
    background-color: #e434a5;
    z-index: -15;
}
.square-small {
    left: -2%;
    top: -140px;
    width: 90px;
    height: 90px;
    transform: rotate(55deg);
    opacity: 0.15;
    border-radius: 12px;
    filter: blur(5px);
    background-color: #ffdd0d;
    z-index: -15;
}

.tab-lab-content {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
}

.tab-custom {
    width: 100%;
    position: relative;
    margin-bottom: 85px;
    &.container {
        padding-top: 0;
    }
    h3 {
        font-family: 'Chewy', "Helvetica", sans-serif;
        font-size: 30px;
        color: #e434a5;
        line-height: 1.16;
        text-align: center;
        margin-bottom: 14px;
    }
    p {
        margin-bottom: 28px;
    }
    button {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        width: 260px;
        height: 48px;
        border-radius: 100px;
        background-color: #e434a5;
        display: block;
        margin: 0 auto;
    }
}

.tab-lab {
    h3 {
        color: #e434a5;
    }
    button {
        background-color: #e434a5;
    }
}

.tab-classic {
    h3 {
        color: #3fb3f9;
    }
    button {
        background-color: #3fb3f9;
    }
}

.bg-image-container {
    width: 100%;
    position: relative;
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.labs-container {
    .tabs-container {
        z-index: 20;
        .active-tab {
            border-color: #e434a5;
        }
        button {
            border-color: transparent;
        }
    }
    .collage-page-content {
        overflow: visible;
        .btn-upload-foto {
            background-color: #e434a5;
            z-index: 30;
            position: relative;
        }
    }
    .square-big {
        top: auto;
        bottom: -80px;
        z-index: 15;
    }
    .square-med {
        top: -90px;
        z-index: 10;
    }
    .square-small {
        left: 10%;
        top: -55px;
        z-index: 5;
    }
}

.love-btn-container {
    position: relative;
    max-width: 340px;
    width: 100%;
    margin: 30px auto 0;
    p {
        line-height: 1.38;
        text-align: center;
        margin-bottom: 10px;
        span {
            font-weight: bold;
        }
    }
    .btn-love {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        max-width: 310px;
        width: 90%;
        height: 48px;
        border-radius: 100px;
        background-color: #f0679f;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        margin: 0 auto;
        p {
            margin: 0;
        }
        svg {
            fill: #fff;
            height: 12px;
            margin-top: -2px;
            margin-left: 5px;
        }
    }
}

.icon-heart {
    position: absolute;
    &-1 {
        width: 16px;
        top: -14px;
        left: -10px;
        transform: rotate(-20deg);
    }
    &-2 {
        width: 16px;
        top: 28px;
        left: 0;
    }
    &-3 {
        width: 10px;
        top: 60px;
        left: -10px;
        transform: rotate(-20deg);
    }
    &-4 {
        width: 10px;
        top: 30px;
        right: -10px;
    }
    &-5 {
        width: 16px;
        top: 50px;
        right: -6px;
    }
}

.-show-popup {
    overflow: hidden;
}

.modal-container-body-select {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: rgba(45, 49, 52, 0.7);
    .modal {
        padding: 72px 72px 62px 72px;
    }
    .btns-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 30px;
    }
    .btn-select-style {
        color: #fff;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 49%;
        height: 80px;
        flex-grow: 0;
        flex-shrink: 0;
        border-radius: 20px;
        padding-top: 4px;
    }
    .btn-select-head {
        background-color: #f6704d;
    }
    .btn-select-body {
        background-color: #4d5ef6;
    }
    .btn-cancel {
        color: #b6b6b6;
        background: none;
        display: flex;
        padding: 10px;
        margin: 0 auto;
    }
}

.body-chooser-container {
    position: relative;
    z-index: 200;
    width: 100%;
    max-width: 540px;
}

.body-chooser-creative {
    position: relative;
    margin-bottom: 24px;
    img {
        object-fit: contain;
        width: 100%;
        border-radius: 32px;
    }
    button {
        position: absolute;
        z-index: 30;
        bottom: 0;
        right: 0;
        width: 56px;
        height: 56px;
        box-shadow: 0 0 25px 0 rgba(45,49,52, .4);
        background-color: #F6704D;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 24px;
        }
    }
}

.btns-template-container {
    display: flex;
    overflow: auto;
    box-sizing: border-box;
    padding-bottom: 6px;    
    &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #cdcdcd;
    }
    button {
        width: 54px;
        height: 54px;
        border-radius: 12px;
        margin-right: 8px;
        background-size: cover;
        flex-shrink: 0;
    }

    .btn-switch-to-female {
        background-image: url(/assets/images/bodychooser/female.png);
    }

    .btn-switch-to-male {
        background-image: url(/assets/images/bodychooser/male.png);
    }
}

.body-chooser-loader {
    position: relative;
    background-color: #e5e5e5;
    border-radius: 24px;
    overflow: hidden;
    z-index: 20;
    margin-bottom: 24px;
    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
    .loader-roller-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

@media all and (max-height: 740px) {
    .collage-page {
        padding-bottom: 8px;
        .creative-holder {
            position: relative;
            max-width: 360px;
        }       
    }
}


@media all and (max-height: 660px) {
    .container {
        padding: 15px 15px 0;
    }
    .tab-lab-content {
        padding: 0 15px;
    }
    .main-title {
        margin-bottom: 12px;
    }
}

@media all and (max-height: 620px) {
    .wr-slider {
        margin-top: 2vh;
    }
}

@media all and (max-height: 610px) {
    .collage-page {
        .creative-holder {
            max-width: 300px;
        }       
    }
}

@media all and (max-height: 580px) {
    .collage-page .btn-back {
        margin-bottom: 1.4vh;
    }
    .tabs-container {
        margin-bottom: 4px;
    }
    .collage-page .creative-holder {
        margin-bottom: 20px;
    }
}

@media all and (max-width: 1030px) {
    .logo-container1 {
        left: -120px;
    }
    
    .logo-container2 {
        right: -120px
    }
}

@media all and (max-width: 820px) {
    .logo-container {
        display: flex;
        align-items: center;
        overflow: auto;
        left: -30px;
        position: relative;
        width: calc(100% + 60px);
        padding-bottom: 5px;
        margin-top: 40px;
        img {
            max-width: 80px;
        }
        a {
            margin: 0 21px;
        }
    }
    .logo-container1, .logo-container2 {
        flex-direction: row;
        position: static;
    }
    .btns-template-container {
        position: relative;
        left: -20px;
        width: calc(100% + 40px);
        padding-left: 20px;
    }
}

@media all and (max-width: 650px) {
    .modal-container-body-select {
       .modal {
            padding: 42px 20px 52px;
        }
        .btns-container {
            flex-direction: column;
        }
        .btn-select-style {
            width: 100%;
            height: 60px;
            max-width: 360px;
            margin-top: 20px;
        }
    }
}

@media all and (max-width: 500px) {
    .share-container {
        left: -30px;
        width: calc(100% + 60px);
    }
    &.app-locale--ru .main-title {
        font-size: 28px;
        line-height: 1.2;
    }
    .tabs-container button {
        width: 44px;
        height: 44px;
        //margin-right: 6px;
    }
}

@media all and (max-width: 440px) {
    .main-title {
        font-size: 30px;
        line-height: 1.16;
    }
    .slide {
        width: 250px;
        height: 250px;
    }
    .btns-market-container {
        flex-direction: column;
    }
    .btn-store {
        position: relative;
        justify-content: center;
        max-width: 310px;
        height: 48px;
        border-radius: 25px;
        margin: 8px 0;
        svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 21px;
        }
    }
    .btn-store-content {
        flex-direction: row;
        align-items: baseline ;
    }
    .btn-store-title {
        margin-bottom: 0;
        margin-right: 4px;
    }
    .wr-slider {
        width: calc(100% + 60px);
        position: relative;
        left: -30px;
        margin: 2vh auto 5.5vh;
        .slick-dots {
            bottom: -23px;
        }
    }
}

@media all and (max-width: 400px) {
    .tabs-container button {
        width: 40px;
        height: 40px;
    }
}

@media all and (max-width: 380px) {
    .modal,
    .modal.rate {
        padding: 55px 10px;
    }
    .modal.clones {
        padding: 30px 10px;
    }
}

@media all and (max-width: 375px) {
    .container {
        padding: 15px 15px 0;
    }
    .collage-page-content.container {
        padding-bottom: 15px;
    }
    .logo-container {
        left: -15px;
        width: calc(100% + 30px);
        padding-left: 15px;
    }
    .tabs-container {
        left: -15px;
        width: calc(100% + 30px);
        padding-left: 15px;
    }
    .wr-slider {
        width: calc(100% + 30px);
        left: -15px;
        padding-left: 15px;
    }
}

@media all and (max-width: 368px) {
    .tabs-container button {
        width: 36px;
        height: 36px;
    }
    .modal {
        .modal-bg {
            width: 180%;
            height: 180%;
        }
    }
    .main-title {
        font-size: 26px;
    }
    &.app-locale--ru .main-title {
        font-size: 24px;
    }
}

@media all and (max-width: 350px) {
    .slide {
        width: 210px;
        height: 210px;
    }
}

@media all and (max-width: 342px) {
    .tabs-container button {
        width: 34px;
        height: 34px;
    }
    .modal {
        padding: 20px;
    }
    .btn-refresh-foto {
        left: calc(50% + 126px);
    }
    .collage-page {
        .creative-holder {
            position: relative;
            max-width: 280px;
        }       
    }
    .main-title {
        font-size: 24px;
    }
    .btn-share {
        margin-right: 5px;
    }
}

@media all and (max-width: 368px) and (max-height: 550px) {
    .collage-page .creative-holder {
        max-width: 310px;
        margin-bottom: 30px;
    }
}
