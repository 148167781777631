.achieve {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    font-family: "Poppins", Arial, sans-serif;
    color: #2d3134;
}

.achieve__title {
    font-size: 48px;
    line-height: 150%;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 40px;
}

.achieve__paragraph {
    width: 66%;
    font-size: 24px;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 80px;
}

.achieve__important {
    width: 85%;
    font-size: 32px;
    line-height: 150%;
    font-weight: 600;
    text-align: right;
    margin-left: auto;
    margin-bottom: 160px;
}

.achieve__important span {
    color: #5cce61;
}

.achieve__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.achieve__image {
    margin-top: 32px;
    border-radius: 24px;
}

.achieve__image.full {
    width: 100%;
}

.achieve__filter {
    display: flex;
    align-items: flex-start;
}

.achieve__filter img:first-of-type {
    width: 40%;
    margin-right: 24px;
}

.achieve__filter img:last-of-type {
    width: 28%;
}

.achieve__next {
    margin-top: 80px;
    margin-bottom: 0;
    font-size: 32px;
    line-height: 150%;
}



/*Media-queries*/

@media (max-width: 850px) {

    .achieve__title {
        font-size: 36px;
    }
    .achieve__important {
        margin-bottom: 80px;
        font-size: 28px;
    }

}

@media (max-width: 650px) {

    .achieve__title {
        font-size: 28px;
        margin-bottom: 24px;
    }
    .achieve__paragraph {
        width: 100%;
        font-size: 20px;
        margin-bottom: 60px;
    }
    .achieve__important {
        width: 100%;
        font-size: 24px;
        text-align: center;
        margin-bottom: 60px;
    }
    .achieve__item {
        flex-wrap: wrap;
    }
    .achieve__filter {
        justify-content: space-between;
    }
    .achieve__filter img:first-of-type {
        width: 55%;
        margin-right: 0;
    }
    .achieve__filter img:last-of-type {
        width: 38%;
    }
    .achieve__next {
        font-size: 24px;
        margin-top: 60px;
    }

}

@media (max-width: 450px) {

    .achieve__title {
        font-size: 24px;
    }
    .achieve__paragraph {
        font-size: 16px;
    }
    .achieve__important {
        font-size: 20px;
    }
    .achieve__next {
        font-size: 20px;
        margin-top: 60px;
    }

}

@media (max-width: 360px) {

    .achieve__paragraph {
        margin-bottom: 40px;
    }
    .achieve__important {
        font-size: 18px;
        margin-bottom: 40px;
    }
    .achieve__next {
        font-size: 18px;
        margin-top: 40px;
    }

}