.btn {
    &-photo-lab {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 24px;
        line-height: 1;
        color: $color-secondary;
        text-transform: uppercase;
    }
    &-contacts-form {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        color: #222;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;

        &-header {
            color: $color-black;
            width: 226px;
            height: 80px;
        }
        &-footer {
            font-size: 16px;
            color: $color-secondary;
            display: none;
        }

        @media all and (min-width: 992px) {
            &:hover {
                color: #4d5ef6;
            }
        }
    }
    &-privacy {
        font-family: $font-primary;
        font-weight: 600;
        line-height: 1;
        color: $color-grey-dark;
    }
    &-upload-foto {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 14px;
        color: $color-white;
        text-transform: uppercase;
        width: 200px;
        height: 48px;
        border-radius: 12px;
        background-color: $color-secondary;
        display: block;
        margin: 0 auto 40px;

        &-desktop {
            width: 228px;
            height: 228px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            z-index: 20;
            transition: 0.3s;

            .btn-icon {
                width: 160px;
                height: 160px;
                border-radius: 50%;
                box-shadow: 0 0 60px 0 rgba(10, 19, 101, 0.6);
                background-color: $color-secondary;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s;

                svg {
                    fill: #4d5ef6;
                    animation: none;
                    width: 72px;
                    height: auto;
                    position: static;
                    transition: 0.3s;
                }
            }

            svg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                fill: #4D5EF6;
                transition: 0.3s;
            }

            @media all and (min-width: 992px) {
                &:hover {
                    svg {
                        fill: #0e1da6;
                    }
                    .btn-icon {
                        background: #0e1da6;
                        svg {
                            fill: #0e1da6;
                        }
                    }
                }
            }
        }
    }
    &-store {
        font-family: $font-primary;
        font-weight: 600;
        color: $color-white;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 0.96px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 320px;
        height: 80px;
        border-radius: 20px;
        background-color: $color-primary;
        box-sizing: border-box;
        padding-top: 5px;
        margin: 0 20px;
        svg {
            height: 32px;
            width: 32px;
            fill: $color-white;
            position: relative;
            top: -2px;
            margin-right: 16px;
        }
        &-apple {
           svg {
                top: -5px;
            }
        }
    }
    &-store-huawei {
        font-size: 24px;
        font-family: $font-primary;
        font-weight: 600;
        letter-spacing: 0.96px;
        text-align: left;
        color: $color-primary;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        svg {
            width: 36px;
            margin-right: 12px;
        }

        .btn-store-content {
            position: relative;
            top: 2px;
        }
    }
    &-hashtag {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 14px;
        color: $color-white;
        text-transform: uppercase;
        width: 96px;
        height: 34px;
        border-radius: 6px;
        background-color: $color-green;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px auto 16px;
    }
    &-back {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 16px;
        color: $color-primary;
        text-transform: uppercase;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 72px;
        display: flex;
        align-items: center;
        background-color: $color-white;
        box-shadow: 0 0 25px 0 rgba(10, 19, 101, 0.3);
        z-index: 20;
        padding-left: 24px;
        svg {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
    }
    &-update {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 10px;
        line-height: 1.4;
        letter-spacing: 0.4px;
        color: $color-grey-dark;
        text-transform: uppercase;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 30%;
        width: 100%;
        position: relative;
        top: 20px;
        svg {
            width: 24px;
            height: 24px;
            margin-bottom: 8px;
        }
    }
    &-go-labs {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.64px;
        text-transform: uppercase;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 56px;
        border-radius: 16px;
        background-color: $color-secondary;
        margin: 0 auto;
    }
    &-go-love {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.64px;
        text-transform: uppercase;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 56px;
        border-radius: 16px;
        background-color: $color-pink;
        margin: 0 auto;
    }
}
