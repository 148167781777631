@font-face {
  font-family: "Wicked Mouse";
  src: url("../../fonts/Wicked_Mouse.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

.tooncoin {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80vh;
  padding-top: 30px;
  box-sizing: border-box;
}

.tooncoin__text {
  width: 56%;
}

.tooncoin__title {
  font-family: "Wicked Mouse", Arial, sans-serif;
  font-size: 48px;
  line-height: 150%;
  color: #ffd06a;
  text-shadow: 4px 4px 0 #000;
  -webkit-text-stroke: 1px #000000;
  margin-top: 0;
  margin-bottom: 24px;
}

.tooncoin__subtitle {
  font-family: "Poppins", Arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #2d3134;
  line-height: 150%;
  margin-top: 0;
  margin-bottom: 40px;
}

.tooncoin__subtitle span {
  color: #f0c538;
}

.tooncoin__paragraph {
  font-family: "Poppins", Arial, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
  color: #2d3134;
  margin-top: 0;
  margin-bottom: 56px;
}

.tooncoin__wrapper {
  position: relative;
  width: 100%;
  display: flex;
}

.tooncoin__button {
  display: block;
  font-family: "Poppins", Arial, sans-serif;
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: #2d3134;
  padding: 30px 40px;
  border-radius: 24px;
  box-sizing: border-box;
}

.tooncoin__button:hover {
  background-color: #202224;
}

.tooncoin__button span {
  color: #f0c538;
  font-weight: 700;
}

.tooncoin__mob-image {
  display: none;
}

.tooncoin__image {
  width: 42%;
}

/*Media-queries*/

@media (max-width: 1100px) {

  .tooncoin__text {
    width: 62%;
  }
  .tooncoin__image {
    width: 38%;
  }

}

@media (max-width: 850px) {

  .tooncoin__text {
    width: 100%;
    text-align: center;
  }
  .tooncoin__wrapper {
    justify-content: center;
  }
  .tooncoin__subtitle {
    margin-bottom: 25px;
  }
  .tooncoin__paragraph {
    margin-bottom: 25px;
    font-size: 20px;
  }
  .tooncoin__button {
    max-width: 100%;
    min-width: 80%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .tooncoin__mob-image {
    display: inline-block;
    width: 50%;
  }
  .tooncoin__image {
    display: none;
  }

}

@media (max-width: 600px) {

  .tooncoin__title {
    font-size: 38px;
  }
  .tooncoin__subtitle {
    font-size: 26px;
    line-height: 120%;
  }
  .tooncoin__paragraph {
    font-size: 18px;
    line-height: 120%;
  }
  .tooncoin__mob-image {
    width: 65%;
  }
  .tooncoin__button {
    font-size: 20px;
  }

}

@media (max-width: 420px) {

  .tooncoin__title {
    font-size: 30px;
    margin-bottom: 18px;
  }
  .tooncoin__subtitle {
    font-size: 22px;
    margin-bottom: 18px;
  }
  .tooncoin__paragraph {
    font-size: 16px;
    margin-bottom: 18px;
  }
  .tooncoin__button {
    font-size: 16px;
    padding: 20px;
    width: 100%;
    border-radius: 16px;
  }

}

@media (max-width: 380px) {

  .tooncoin__title {
    font-size: 24px;
    text-shadow: 2px 2px 0 #000;
  }
  .tooncoin__subtitle {
    font-size: 20px;
  }
  .tooncoin__button {
    white-space: initial;
    padding: 15px 10px;
  }

}

@media (max-width: 320px) {

  .tooncoin__subtitle {
    font-size: 19px;
  }
  .tooncoin__button {
    font-size: 15px;
  }

}
