.watermark-container {
    display: none;
    height: 68px;
    max-width: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 14px;
    z-index: 30;
    .tooltip {
        position: absolute;
        bottom: 36px;
        right: 0;
        left: 4px;
        width: 100%;
        background-color: rgba(0,0,0,.75);
        border-radius: 8px;
        padding: 12px 10px;
    }
    p {
        font-size: 16px;
        text-align: center;
        color: $color-white;
        margin: 0;
    }
    .btn-remove-logo {
        height: 30px;
        width: 190px;
        position: absolute;
        right: -14px;
        bottom: 0;
        cursor: pointer;
        margin: 0;
    }
    .btn-logo-on {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.17;
        text-transform: uppercase;
        color: $color-secondary;
        position: absolute;
        bottom: -50px;
        right: -8px;
        background-color: transparent;
        padding: 20px 10px 5px;

        // &.without-tabs {
            // bottom: -30px;
        // }
    }
    .creative-subname {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.17;
        text-transform: uppercase;
        color: $color-secondary;
        position: absolute;
        bottom: -27px;
        right: 14px;
        background-color: transparent;
        padding: 20px 10px 5px;
        max-width: 40%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    }
    &-ja {
        display: block;
        right: auto;
        left: 14px;
        height: 0;
        .tooltip {
            right: auto;
            left: 0;
        }
        .btn-logo-on {
            right: auto;
            left: -8px;
        }
    }

    &-static {
        position: relative;
        left: 0;
        top: 0;
        right: auto;
        bottom: auto;
        height: auto;
    }
}

.watermark-desktop-container {
    position: absolute;
    left: calc(100% + 24px);
    bottom: 12px;
    z-index: 40;
    width: max-content;
    button {
        background-color: transparent;
        display: flex;
        align-items: center;
    }
    svg {
        width: 32px;
        flex-shrink: 0;
        margin-right: 16px;
    }
    span {
        flex-shrink: 0;
    }
    .btn-logo-on {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 24px;
        line-height: 1;
        letter-spacing: 0.96px;
        text-transform: uppercase;
        color: $color-secondary;
    }
    .btn-logo-off {
        font-size: 24px;
        line-height: 1;
        color: $color-grey-dark;
    }
}

@media all and (max-width: 900px) {
    .watermark-container {
        display: block;
    }
    .watermark-desktop-container {
        display: none;
    }

    .watermark-container-subtabs {
        position: absolute;
        left: 5px;
        bottom: -130px;

        .btn-logo-on {
            bottom: 0;
            padding: 5px;
        }
    }
}

@media all and (max-width: 380px) {
    .watermark-container {
        max-width: 100%;
        .btn-logo-on {
            text-align: left;
        }
    }
}
